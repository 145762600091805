import React, { useEffect, useState } from "react";
import { Tag, Popconfirm } from "antd";
import { useToast } from "@chakra-ui/react";
import Navbar from "../../Layouts/Navbar/Navbar";
import {
  getPrices,
  setStripeSession,
  cancelSubscriptionApi,
  getLoggedInUserApi,
  createStripeSubsrption,
} from "../../utils/Api";
import "./Subscription.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hook/useAlert";
import TermsAndConditions from "../../Layouts/modal/TermsAndConditions/TermsAndConditions";
const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prices, setPrices] = useState([]);

  const [subStatus, setSubStatus] = useState(false);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { data: loggedInUserData } = useSelector((state) => state.user);

  const toast = useToast();
  const { SetRefreshNow } = bindActionCreators(actionCreators, dispatch);
  const { open: openAlert } = useAlert();
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");

  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      toast({
        title: "Failed",
        description: "Order canceled -- please Try again",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
    }
  }, []);

  useEffect(() => {
    fetchPrices();
    if (loggedInUserData && success) {
      const userId = loggedInUserData?._id;
      if (query.get("success")) {
        if (query.get("oneTime")) {
          createStripeSubsrption({
            userId,
            sessionId,
            oneTime: true,
            priceId: query.get("product"),
          }).then(() => {
            setSuccess(false);
            setSessionId("");
            toast({
              title: "Success",
              description:
                "Order placed! You will receive an email confirmation.",
              status: "success",
              duration: 5000,
              position: "top",
              isClosable: true,
            });

            navigate("/subscription");
          });
        } else {
          try {
            createStripeSubsrption({
              userId,
              sessionId,
            }).then((res) => {
              if (res.status === 200) {
                setSuccess(false);
                setSessionId("");
                toast({
                  title: "Success",
                  description:
                    "Order placed! You will receive an email confirmation.",
                  status: "success",
                  duration: 5000,
                  position: "top",
                  isClosable: true,
                });
                SetRefreshNow();
                navigate("/subscription");
              }
            });
          } catch (error) {
            toast({
              title: "Failed",
              description: error.message,
              status: "error",
              duration: 5000,
              position: "top",
              isClosable: true,
            });
          }
        }
      }
    }

    setSubStatus(loggedInUserData?.subscription?.active ? true : false);
  }, [loggedInUserData, success]);

  const fetchPrices = async () => {
    const { data: response, status } = await getPrices();
    if (status !== 200) throw Error(response.message);
    setPrices(response?.data);
  };

  const createSession = async (subscribeWith) => {
    const { priceId, type } = subscribeWith;
    if (!loggedInUserData) {
      return openAlert({ text: "You need to login first", type: "error" });
    }
    if (subStatus) {
      return openAlert({
        text: "First You neeed to cancel your current  Subscription ",
        type: "error",
      });
    }

    const { data: response, status } = await setStripeSession({
      type,
      priceId: priceId,
      userId: loggedInUserData?._id,
      redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}`,
    });

    if (status !== 200) throw Error(response.message);
    window.location.href = response.url;
    // navigate(-1)
  };

  const cancelSubscription = async () => {
    const { data: response, status } = await cancelSubscriptionApi({
      subscriptionId: loggedInUserData?.subscription?.subscription_id,
    });

    if (status !== 200) throw Error(response.message);
    const res = await getLoggedInUserApi();

    if (res.status === 200) {
      // setSubStatus(res.data.message.subscription.status);
      setOpen(false);
      setConfirmLoading(false);
      SetRefreshNow();
      // AddLoggedInUser
    }

    toast({
      title: "",
      description: "Subscription has been canceled successfully",
      status: "success",
      duration: 5000,
      position: "top",
      isClosable: true,
    });
  };

  const canCancelPackage = (packagePriceId) => {
    const userPriceId = loggedInUserData?.subscription?.stripe_price_id;
    const canCancel =
      loggedInUserData?.subscription?.active &&
      loggedInUserData?.subscription?.payment_type === "online" &&
      userPriceId === packagePriceId;
    return canCancel;
  };

  const showBuyNowButton = !loggedInUserData?.subscription?.active;

  return (
    <>
      <Navbar />
      <div className={"subscription_container"}>
        <div className="subscription_header_box">
          <img
            width="64"
            height="64"
            src="https://img.icons8.com/external-tal-revivo-tritone-tal-revivo/64/external-discount-coupons-at-a-shopping-mall-store-for-reasonable-pricing-in-sales-mall-tritone-tal-revivo.png"
            alt="external-discount-coupons-at-a-shopping-mall-store-for-reasonable-pricing-in-sales-mall-tritone-tal-revivo"
          />
          <h1>Choose Your Plan</h1>
        </div>
        <div className="pricing">
          <div className="pricing-plan">
            <h2 className="plan_name">Basic</h2>
            <p>
              <span>Free</span>
            </p>
            <ul>
              <li>Watch Debate</li>
              <li>Live Chat on Debate</li>
              <li>Vote on Debate</li>
            </ul>
          </div>

          {prices.map((price) => (
            <div className="pricing-plan" key={price?.key}>
              <div className="plan_main_content_box">
                <h2 className="plan_name">{price?.nickname}</h2>
                <h3>
                  {canCancelPackage(price?.id) && price?.id && (
                    <Tag color="green">Current Plan</Tag>
                  )}
                </h3>
                <p>
                  <span>£{price?.unit_amount / 100}</span>
                </p>
                <ul>
                  <li>Watch Debate</li>
                  <li>Live Chat on Debate</li>
                  <li>Vote on Debate</li>
                  <li>Take Part in Debate</li>
                  <li>Create Debate</li>
                  <li>Connect to Coaches</li>
                </ul>
              </div>
              {canCancelPackage(price?.id) ? (
                <Popconfirm
                  title="Cancel Subscription"
                  description="Are you sure, you want to cancel?"
                  open={open}
                  onConfirm={cancelSubscription}
                  okButtonProps={{
                    loading: confirmLoading,
                    danger: true,
                  }}
                  okText="Yes"
                  cancelText="No"
                  onCancel={() => setOpen(false)}
                >
                  <button href onClick={() => setOpen(true)}>
                    Cancel Plan
                  </button>
                </Popconfirm>
              ) : null}
              {showBuyNowButton && (
                <button
                  onClick={() =>
                    createSession({ priceId: price?.id, type: price?.type })
                  }
                >
                  Buy Now
                </button>
              )}
            </div>
          ))}
        </div>
        <TermsAndConditions>
          <button className={"termsAndConditionButtons"}>
            Terms And Conditions
          </button>
        </TermsAndConditions>
      </div>
    </>
  );
};

export default Subscription;
