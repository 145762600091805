import DatePicker from "react-datepicker";
import { Enums } from "../../redux/action/actionTypes/Enumss";
import { MdOutlineTopic } from "react-icons/md";
import { VscTypeHierarchy } from "react-icons/vsc";
import { BiCode } from "react-icons/bi";
import { AiOutlineFieldTime, AiOutlineTeam } from "react-icons/ai";
import { HiSquaresPlus } from "react-icons/hi2";

import { debateFormat } from "../../utils/data";

const MainDebateInput = ({
  debateForm,
  handleInputChange,
  handleAutoGenerateePasscode,
  handleStartDate,
  handlePassCodeChange,
  startTime,
  filterPassedTime,
}) => {
  return (
    <div className="input_box_wrappers flex flex-wrap">
      {/* <DebateMotionModal activeTopic={debateForm.topic} handleDebateTopicChange={handleDebateTopicChange}> */}
      <div className="flex w-full">
        <div className="input_item">
          <MdOutlineTopic />
          <input
            type="text"
            placeholder="Topic for debate"
            name="topic"
            onChange={handleInputChange}
            value={debateForm.topic}
          />
        </div>
      </div>
      <div className="flex w-full gap-x-2">
        <div className="input_item flex-[1]">
          <VscTypeHierarchy />
          <select
            id=""
            name="type"
            value={debateForm?.type}
            onChange={handleInputChange}
          >
            <option selected disabled value={""}>
              choose debate type
            </option>

            {Array.from(debateFormat).map(([debateType]) => (
              <option value={debateType} key={debateType}>
                {debateType}
              </option>
            ))}
          </select>
        </div>
        <div className="input_item flex-[1]">
          <div className="input_row_box_parent ">
            <BiCode />
            <input
              type="text"
              minLength={6}
              maxLength={6}
              max={6}
              placeholder="random 6 digit number"
              name="passcode"
              onChange={handlePassCodeChange}
              value={debateForm.passcode}
            />
            <button onClick={handleAutoGenerateePasscode}>Auto Generate</button>
          </div>
          {/* {
!isPasscodeValid && <label className='error_text' >This passcode is already taken  try next ! </label>} */}
        </div>
      </div>
      <div className="flex w-full gap-x-2">
        <div className="form_input_row_box input_item flex-[1]">
          <AiOutlineFieldTime />

          <div className="starting_time_item_box">
            <div className="starting_time_item">
              <DatePicker
                selected={startTime}
                onChange={(date) => handleStartDate(date)}
                showTimeInput
                timeInputLabel="Time:"
                filterTime={filterPassedTime}
                dateFormat="yyyy/MM/dd , h:mm aa"
              />
            </div>
          </div>
        </div>
        <div className="input_item flex-[1]">
          <AiOutlineTeam />
          {/* <select value={debateForm.team_format}  name='team_format' className='team_format' onChange={handleInputChange}>
          <option value="" selected disabled>chose team format</option>
          {
            returnTeamFormatOptions()
          }

        </select> */}
          <select
            name="isPublic"
            value={debateForm.isPublic}
            onChange={handleInputChange}
          >
            <option value="">private or public</option>
            <option value={Enums.PUBLIC_DEBATE}>Everyone can see </option>
            <option value={Enums.PRIVATE_DEBATE}>Only team can see</option>
          </select>
        </div>
      </div>
      <div className="flex w-full gap-x-2">
        <div className="input_item">
          <HiSquaresPlus />
          <select
            value={debateForm.judgeType}
            name="judgeType"
            className="team_format"
            onChange={handleInputChange}
          >
            <option value={""} disabled selected>
              Choose Judge Type
            </option>
            <option value={`${Enums.AIJUDGE}`}>Winning AI Judge</option>
            <option value={`${Enums.NO_WINNING_AI_JUDGE}`}>
              No Winning AI Judge{" "}
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default MainDebateInput;
