import { BiMessageSquareEdit } from "react-icons/bi";
import { MdOutlineDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { Tr, Td } from "@chakra-ui/react";
import styles from "./usertable.module.css";
import { deleteUserApi } from "../../utils/Api";
import useAlert from "../../hook/useAlert";
import { ConfirmationModal } from "../modal/ConfirmationModal";
const UserTableItem = ({ user, setAllUsers }) => {
  const { open } = useAlert();
  const handleDeleteUser = async (userId) => {
    try {
      const { status } = await deleteUserApi(userId);

      if (status === 200) {
        open({ type: "success", text: "user deleted successfully" });
        setAllUsers((prev) => {
          return prev.filter((user) => user._id !== userId);
        });
      } else {
        throw new Error("something went wrong ");
      }
    } catch (error) {
      open({ type: "error", text: "failed to delete user" });
    }
  };

  return (
    <Tr
      style={{
        width: "100%",
      }}
    >
      <Td>
        {user.firstName} {user.lastName}
      </Td>
      <Td> {user.email}</Td>
      <Td>{user.verified.toString()}</Td>
      <Td>{user.points}</Td>
      <Td> {user.country}</Td>
      <Td> {user.subscription?.payment_type || "unpaid"}</Td>
      <Td>
        <div className={styles.action_btns}>
          <ConfirmationModal
            onCancel={() => {}}
            onConfirm={() => handleDeleteUser(user._id)}
            confirmationText="Are you sure you want to delete this user?"
          >
            <MdOutlineDelete className={styles.deleteIcon} />
          </ConfirmationModal>
          <Link to={`edit/${user._id}`}>
            <BiMessageSquareEdit className={styles.editIcon} />
          </Link>
        </div>
      </Td>
    </Tr>
  );
};

export default UserTableItem;
