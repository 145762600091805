import { useDispatch, useSelector } from "react-redux";
import "./ProfileCard.css";
import { BsFillCalendarFill } from "react-icons/bs";
import { getUsersDebateCountApi, logoutApi } from "../../../utils/Api";
import { IoLogOutOutline } from "react-icons/io5";
import { bindActionCreators } from "redux";
import { useToast } from "@chakra-ui/react";
import { actionCreators } from "../../../redux/store";
import { useEffect, useState } from "react";
import { getFlag } from "../../../utils/services";
import { UpdateProfile } from "../../modal/UpdateProfile";
const ProfileCard = ({ userData }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { RemoveLoggedInUser } = bindActionCreators(actionCreators, dispatch);
  const { data } = useSelector((state) => state.user);

  const [flag, setFlag] = useState("");

  const [debatesCount, setDebatesCount] = useState({
    upcomingDebate: null,
    liveDebate: null,
    allDebates: null,
  });
  const [isMe, setIsMe] = useState(null);

  useEffect(() => {
    if (!userData) return;
    let newAvatars = [];
    userData.equipedAvatars.forEach((a) => {
      if (newAvatars.some((avt) => avt.avatar === a.avatar)) {
      } else {
        newAvatars.push(a);
      }
    });
  }, [userData]);

  const handleLogout = async () => {
    try {
      const res = await logoutApi();
      if (res.status === 200) {
        RemoveLoggedInUser();
        toast({
          title: "",
          description: "You Logged out successfully",
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      } else {
        throw Error("something went wrong");
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleGetDebatesCount = async () => {
    try {
      const res = await getUsersDebateCountApi(userData?._id);
      console.log(res);
      if (res.status === 200) {
        const { liveDebate, upcomingDebate, allDebates } = res.data.message;
        setDebatesCount({
          liveDebate,
          upcomingDebate,
          allDebates,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSubscriptionPlan = () => {
    if (!data) return;
    const { subscription } = data;
    if (subscription?.active) {
      return subscription?.plan;
    } else if (
      !subscription?.status ||
      subscription?.status === "canceled" ||
      !subscription
    ) {
      return "Free";
    }
  };

  useEffect(() => {
    getFlag(userData?.country).then((flag) => {
      setFlag(flag);
    });
    if (!userData?._id) return;
    if (userData._id === data?._id) {
      setIsMe(true);
    } else {
      setIsMe(false);
    }
    handleGetDebatesCount();
  }, [data, userData]);

  return (
    <div className="profileCardContainer">
      <div className="ProfileCard">
        <div className="profile_card_top">
          <div className="profile_card_left">
            <div className="profile_basic_info_item">
              <div className="profile_card_avatar_wrapper">
                <img
                  draggable={false}
                  src={userData?.avatar}
                  alt={`${userData.firstname}'s avatar`}
                />
              </div>
              <div className="profile_card_item basic_userinfo_item">
                <div className="user_main_detail">
                  <div className="profile_name_user_point_box">
                    <h1 className="profile_card_username">
                      {`${userData?.firstName} ${userData?.lastName || ""}`}
                    </h1>

                    <div className="profile_card_user_points">
                      <p>{Math.trunc(userData?.points)}</p>
                      <img
                        width="94"
                        height="94"
                        src="https://img.icons8.com/3d-fluency/94/popular-topic.png"
                        alt="popular-topic"
                      />
                    </div>
                  </div>
                  <p className="profile_card_email">{userData?.email}</p>
                  <div className="profile_card_user_country_box">
                    <img width={"20px"} src={flag} alt="" />
                    <p>{userData?.country} </p>
                  </div>
                </div>

                <div className="profile_action_button">
                  <UpdateProfile
                    firstName={`${userData?.firstName || ""}`}
                    lastName={`${userData?.lastName || ""}`}
                    email={userData?.email || ""}
                    profilePicture={userData?.avatar || ""}
                  >
                    <button className="update_profile_button">
                      Update Profile
                    </button>
                  </UpdateProfile>
                  {isMe === null ? (
                    ""
                  ) : isMe ? (
                    <button onClick={handleLogout} className="logout_button">
                      <IoLogOutOutline width={25} />
                      <p>Log out</p>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="profile_card_item_other_item">
            {/* <h1 className="profile_card_item_title">OTHER INFORMATION</h1> */}

            <div className="other_info_text_item">
              <img
                width="94"
                height="94"
                src="https://img.icons8.com/3d-fluency/94/micro.png"
                alt="micro"
              />
              <p className="other_info_text">
                {" "}
                Created {debatesCount.allDebates} Debates
              </p>
            </div>
            <div className="other_info_text_item">
              <img
                width="94"
                height="94"
                src="https://img.icons8.com/3d-fluency/94/alarm-clock--v2.png"
                alt="alarm-clock--v2"
              />

              <p className="other_info_text">
                {" "}
                Upcoming {debatesCount.upcomingDebate} Debates
              </p>
            </div>

            {userData?.subscription !== null && data && (
              <>
                <div className="other_info_text_item">
                  <img
                    width="94"
                    height="94"
                    src="https://img.icons8.com/3d-fluency/94/best-seller.png"
                    alt="best-seller"
                  />

                  <p className="other_info_text">
                    {" "}
                    Plan: {getSubscriptionPlan()}
                  </p>
                </div>
                <div className="other_info_text_item">
                  <img
                    width="94"
                    height="94"
                    src="https://img.icons8.com/3d-fluency/94/best-seller.png"
                    alt="best-seller"
                  />

                  <p className="other_info_text">
                    {" "}
                    Payment Type: { userData?.subscription?.payment_type}
                  </p>
                </div>
                {userData?.subscription?.status === "active" && (
                  <div className="other_info_text_item">
                    <BsFillCalendarFill className="upcoming_icon" />
                    <p className="other_info_text">
                      Reamining Days: {userData?.subscription?.remainingDays}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* {
                isMe === null ? "" : isMe ? <AvatarCarousel currentAvatar={userData?.avatar} onChange={handleUpdateAvatar} /> : ""
                
            } */}
      </div>
    </div>
  );
};

export default ProfileCard;

//  <div>
//    {userData?.equipedAvatars?.length > 0 && (
//      <>
//        <h1 className="profile_card_item_title">Equipped Avatars</h1>

//        <div className="image-row">
//          {userAvatar?.map((item, index) => (
//            <img
//              className="circle-image"
//              width={"40px"}
//              src={item.avatar}
//              alt="avatar1"
//              onClick={() => handleUpdateAvatar(item.type, item.avatar)}
//            />
//          ))}
//        </div>
//      </>
//    )}
//  </div>;
