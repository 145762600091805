import moment from "moment";
import React from "react";
import { TournamentParticipateModal } from "../../modal/TournamentParticipateModal";
import { calculateAge } from "../../../utils/services";
// import { useNavigate } from "react-router-dom";

const TournamentItem = ({ tournament }) => {
  // const navigate = useNavigate();
  // const handleJoinJudgeDebate = () => {
  //   navigate(`/judge-debate/${tournament._id}`);
  // }

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-y-3 flex-col">
          <div className="flex flex-col gap-y-4 justify-between pr-2 ">
            <div>
              <p className="text-xl font-semibold  ">
                Debate Date - {moment(tournament.start_date).format("llll")}{" "}
              </p>
              <p className="text-sm text-red-500">
                Register your interest by{" "}
                {moment(tournament.registration_deadline).format("llll")}
              </p>
              <div className="flex text-sm gap-x-1 underline">
                Judge :
                <p className="">
                  {tournament?.judge_id?.firstName}{" "}
                  {tournament?.judge_id?.lastName || ""}
                </p>
              </div>
            </div>
            {/* <button className="bg-blue-600 text-white p-2 rounded-md px-5 w-fit " onClick={handleJoinJudgeDebate}>JOIN LIVE DEBATE</button> */}
          </div>

          <p className="text-sm text-gray-600 mt-2">Description : {tournament.description}</p>
        </div>
      </div>

      <div className="flex flex-col gap-y-5 ">
        {tournament?.tournament_motions?.map((motion) => (
          <div className="flex flex-col gap-y-3   py-4 shadow-sm">
            <div className="flex flex-col ">
              <p className="font-medium">{motion.title}</p>
              <div className=" rounded-md font-bold text-gray-600  w-fit text-[12px]">
                {motion.debate_type}
              </div>
            </div>
            <div>
              {motion.teams?.map((team) => (
                <div className="flex items-center gap-x-1">
                  <b>{team.name} :</b>
                  <div className="text-sm flex items-center gap-x-1">
                    {/* Ram Harish,{" "} */}
                    {/* <div className="text-white px-1 rounded-sm  bg-green-400">
                            Available
                          </div> ,
                         {" "} */}

                    {Array.from(new Array(team.max_members)).map((mem, index) =>
                      team.members[index]?.firstName ? (
                        <div className="  px-1 rounded-sm">
                          {team.members[index]?.firstName}
                          {calculateAge(team.members[index]?.dob) || ""}
                        </div>
                      ) : (
                        <TournamentParticipateModal
                          motion={motion}
                          team_name={`${team.name} ${index + 1}`}
                          team_id={team._id}
                          start_date={moment(tournament.start_date).format(
                            "llll"
                          )}
                          judge_name={`${tournament?.judge_id?.firstName}
                      ${tournament?.judge_id?.lastName || ""}`}
                        >
                          <div className="bg-green-400 text-white  px-1 rounded-sm">
                            <p>Available</p>
                          </div>
                        </TournamentParticipateModal>
                      )
                    )}
                  </div>
                </div>
              ))}

              {/* <div className="flex items-center gap-x-1">
                      <b>CON :</b>
                      <div className="text-sm flex items-center gap-x-1">
                        Ram Harish, <p className="text-green-400">Available</p>
                        <p className="text-green-400">Available</p>{" "}
                      </div>
                    </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TournamentItem;
