import React from "react";
import { Divider, Box, Button } from "@chakra-ui/react";
import CoachItem from "../../../components/Coaches/CoachItem/CoachItem";
import "./manageCoaches.css";
import CreateCoachModal from "../../../components/modal/CreateCoach";
import { getCoachesByTypeApi } from "../../../utils/Api";

// const seniorCoaches = [
//   {
//     id: 1,
//     name: "John Smith",
//     country: "US",
//     image: "https://picsum.photos/200/300?random=1",
//     desc: "Experienced senior coach with over 20 years in professional sports training and team management.",
//   },
//   {
//     id: 2,
//     name: "Maria Garcia",
//     country: "ES",
//     image: "https://picsum.photos/200/300?random=2",
//     desc: "Specializes in strategic coaching and has led teams to multiple championships.",
//   },
//   {
//     id: 3,
//     name: "Liam Brown",
//     country: "UK",
//     image: "https://picsum.photos/200/300?random=3",
//     desc: "Expert in performance optimization with a focus on senior athletes.",
//   },
// ];

// const youthCoaches = [
//   {
//     id: 4,
//     name: "Sophie Lee",
//     country: "CA",
//     image: "https://picsum.photos/200/300?random=4",
//     desc: "Passionate about youth development and fostering talent from an early age.",
//   },
//   {
//     id: 5,
//     name: "Aiden Patel",
//     country: "IN",
//     image: "https://picsum.photos/200/300?random=5",
//     desc: "Focuses on building confidence and skills in young athletes.",
//   },
//   {
//     id: 6,
//     name: "Emma Dubois",
//     country: "FR",
//     image: "https://picsum.photos/200/300?random=6",
//     desc: "Youth coach with a background in psychology and sports education.",
//   },
// ];
const ManageCoaches = () => {
  const [seniorCoaches, setSeniorCoaches] = React.useState([]);

  const [youthCoaches, setYouthCoaches] = React.useState([]);

  const fetchCoaches = async (type) => {
    try {
      const response = await getCoachesByTypeApi(type);
      console.log(response);
      return response?.data?.data || [];
    } catch (error) {
      console.error("Error fetching coaches:", error);
    }
  };

  const fetchSeniorCoaches = async () => {
    const coaches = await fetchCoaches("senior");
    setSeniorCoaches(coaches);
  };
  const fetchYouthCoaches = async () => {
    const coaches = await fetchCoaches("youth");
    setYouthCoaches(coaches);
  };

  const fetchCoachesInit = async () => {
    await fetchSeniorCoaches();
    await fetchYouthCoaches();
  };

  React.useEffect(() => {
    fetchCoachesInit();
  }, []);

  return (
    <Box p={4}>
      <div className="coachBoxHeader">
        <h1 className="text-black">Senior Coaches</h1>
        <CreateCoachModal type={"senior"} onSuccess={fetchCoachesInit}>
          <Button variant={"solid"}>Create Senior Coach</Button>
        </CreateCoachModal>
      </div>
      <div className="coachBoxContainer">
        {seniorCoaches.map((coach) => (
          <CoachItem
            key={coach.id}
            data={coach}
            format="senior"
            onSuccess={fetchCoachesInit}
          />
        ))}
      </div>

      <Divider my={6} />

      <div className="coachBoxHeader">
        <h1 className="text-black">Youth Coaches</h1>
        <CreateCoachModal type={"youth"} onSuccess={fetchCoachesInit}>
          <Button>Create Youth Coach</Button>
        </CreateCoachModal>
      </div>
      <div className="coachBoxContainer">
        {youthCoaches.map((coach) => (
          <CoachItem
            key={coach.id}
            data={coach}
            format="youth"
            onSuccess={fetchCoachesInit}
          />
        ))}
      </div>
    </Box>
  );
};

export default ManageCoaches;
