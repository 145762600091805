import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import styles from "./calendlyModal.module.css";

function CalendlyModal({ children, coach, flag, format }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={"95%"} maxWidth={"900px"}>
          <div className={styles.calendly_coach_box}>
            <div className={styles.coach_info}>
              <img className={styles.avatar_img} src={coach.image} alt="" />

              <h1 className={styles.coach_name_text}> {coach?.name}</h1>
              <img className={styles.flag_img} src={flag} alt="" />
            </div>
            {format ? (
              coach?.about?.split("|")?.map((d, i) => (
                <p className={styles.formatedParagph} key={i}>
                  {d}
                </p>
              ))
            ) : (
              <p className={styles.coach_desc_text}>{coach?.about}</p>
            )}
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
export default CalendlyModal;
