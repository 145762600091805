import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Input,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllDebaiUsersApi } from "../../utils/Api";
import UserTableItem from "./UserTableItem";

const UserTable = () => {
  const [debAiUsers, setDebAiUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchDebAiUsers();
  }, []);

  const fetchDebAiUsers = async () => {
    try {
      const { data, status } = await getAllDebaiUsersApi();
      if (status === 200) {
        setDebAiUsers(data.message);
      } else {
        throw data.message;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (search.length > 0) {
      const filtered = debAiUsers.filter((user) => {
        const username = `${user.firstName} ${user.lastName}`;
        return username.includes(search);
      });
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(debAiUsers);
    }
  }, [search, debAiUsers]);

  return (
    <div style={{ width: "100%" }}>
      <Input
        placeholder="Search user"
        onChange={(e) => setSearch(e.target.value)}
        style={{
          height: "50px",
          border: "1px solid gray",
          background: "#e0e0e03c",
        }}
      />
      <TableContainer
        style={{
          width: "100%",
        }}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Email</Th>
              <Th>Verfied</Th>
              <Th isNumeric>Points</Th>
              <Th>Country</Th>
              <Th>Payment Type</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.map((user) => (
              <UserTableItem
                setAllUsers={setDebAiUsers}
                user={user}
                key={user._id}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserTable;
