import React, { useEffect, useState } from "react";
import styles from "./liveDebates.module.css";

import { LiveDebateItem } from "./liveDebateItem/LiveDebateItem";
import { useSelector } from "react-redux";
import { AmIParticipants } from "../../utils/services";
const LiveDebates = () => {
  // const [liveDebates, setLiveDebates] = useState([]);

  const { notEndedDebates } = useSelector((state) => state.debate);
  const { data: userData } = useSelector((state) => state.user);

  const [allDebates, setAllDebates] = useState([]);

  useEffect(() => {
    handleFilterDebatesToDisplay();
  }, [notEndedDebates]);

  const handleFilterDebatesToDisplay = () => {
    // show all the public debates and if the debate is private show  it to the participants of the debate

    const filtered = notEndedDebates.filter((deb) => {
      if (deb.isPublic) {
        return true;
      }

      if (userData) {
        return AmIParticipants(deb.teams, userData._id);
      } else {
        return false;
      }
    });

    setAllDebates(filtered);
  };

  return (
    <div className={styles.live_debate_box}>
      <div className={styles.live_debate_heading}>
        <h2 className={styles.live_debate_heading_text}>
          {" "}
          <img
            width="48"
            height="48"
            src="https://img.icons8.com/fluency/48/radio-waves.png"
            alt="radio-waves"
          />{" "}
          UPCOMING DEBATES
        </h2>
      </div>
      <div className={styles.live_debate_debates_wrapper}>
        {allDebates.length > 0 ? (
          allDebates?.map((deb) => (
            <LiveDebateItem debate={deb} key={deb._id} />
          ))
        ) : (
          <>
            <div className={styles.no_live_debates_box}>
              <img
                src="/images/nolivedebate.png"
                className={styles.noLiveDebatesImage}
              />
              <p>No upcoming debates </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LiveDebates;
