import React, { useState } from "react";
import { Label, TextInput } from "flowbite-react";
import { Badge } from "flowbite-react";
import SearachUserPopover from "../../../../Layouts/popovers/selectParticipants/SelectParticipants";
import { BiXCircle } from "react-icons/bi";

const TournamentTeam = ({
  team,
  index,
  handleSelectParticipants,
  handleRemoveParticipant,
}) => {
  const [searchText, setSearchText] = useState("");

  return (
    <div className="flex-1">
      <Label
        htmlFor="small"
        value={`${team.name} `}
      />
      <div className="flex flex-col gap-y-2">
        <TextInput
          placeholder="Search participants"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="flex flex-wrap items-center gap-2">
          {team.members.map((t) => (
            <Badge key={t.id} className="flex items-center gap-1 px-2 py-1">
              {t.firstName}
              <BiXCircle
                className="w-4 h-4 cursor-pointer text-red-500 hover:text-red-700"
                onClick={() => handleRemoveParticipant(t._id, index)}
              />
            </Badge>
          ))}
        </div>
      </div>
      {searchText.length > 0 && (
        <SearachUserPopover
          team={team}
          index={index}
          handleSelectParticipants={handleSelectParticipants}
          selectedParticipants={team.members}
          participantsSearchInput={searchText}
        >
          <div></div>
        </SearachUserPopover>
      )}
    </div>
  );
};

export default TournamentTeam;
