import React, { useEffect } from "react";
import { getCoachesApi } from "../../utils/Api";
import CoachItem from "./CoachItem/CoachItem";
import styles from "./coaches.module.css";

const Coaches = () => {
  // fetch coaches

  const [coaches, setCoaches] = React.useState([]);
  const fetchCoaches = async () => {
    try {
      const response = await getCoachesApi();
      console.log(response);
      setCoaches(response?.data?.data || []);
    } catch (error) {
      console.log(error);
      console.error("Error fetching coaches:", error);
    }
  };

  useEffect(() => {
    fetchCoaches();
  }, []);
  return (
    <div className={styles.Coaches}>
      <div className={styles.coachContentWrapper}>
        <h1 className={styles.top_header_text}>Debai Coaches</h1>

        <div className={styles.coach_container}>
          {coaches.map((coach, index) => (
            <CoachItem key={index} data={coach} onSuccess={fetchCoaches} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Coaches;
