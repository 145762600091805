import Coaches from "../../components/Coaches/Coaches";
import LiveDebates from "../../components/LiveDebates/LiveDebates";
import TopDebators from "../../components/TopDebators/TopDebators";
import Banner from "../../Layouts/banner/Banner";
import Navbar from "../../Layouts/Navbar/Navbar";
import "./Home.css";
// import YouthCoach from "../../components/Coaches/YouthCoach/YouthCoach";
import WeeklyDebateResults from "../../components/Home/WeeklyDebateResults/WeeklyDebateResults";
import WeeklyDebate from "../../components/Home/weeklyDebate/WeeklyDebate";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="home_wrapper">
        <Banner />
        <div className="home_bottom_info">
          <LiveDebates />
          <TopDebators />
          <WeeklyDebateResults />
        </div>
        <div className="flex gap-x-10 max-w-[1200px] w-[95%] mx-auto ">
          <WeeklyDebate />
        </div>
        <Coaches />
        {/* <YouthCoach /> */}
      </div>
    </>
  );
};

export default Home;
