import { useParams } from "react-router-dom";
import styles from "./EditUserForm.module.css";
import {
  getCountries,
  searchUserByIdApi,
  updateUserapi,
} from "../../../utils/Api";
import { useEffect, useState } from "react";
import { DebAiCountriesList } from "../../../utils/data";
import useAlert from "../../../hook/useAlert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditUserForm = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [countryList, setCountryList] = useState([]);
  const { open } = useAlert();
  const [endDate, setEndDate] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    verified: null,
    email: "",
    country: "",
    points: "",
  });

  useEffect(() => {
    if (!id) return;
    fetchUserToEdit();
  }, [id]);

  useEffect(() => {
    if (!user._id) return;
    const subscriptionEndDate = user?.subscription?.endDate
      ? new Date(user?.subscription?.endDate)
      : null;
    if (subscriptionEndDate) {
      setEndDate(new Date(subscriptionEndDate));
    }
    Object.entries(user).forEach(([key1, value1]) => {
      Object.entries(userData).forEach(([key, value]) => {
        if (key === key1) {
          setUserData((prev) => ({
            ...prev,
            [key1]: value1,
          }));
        }
      });
    });
  }, [user]);

  useEffect(() => {
    handleGetCountries();
  }, [DebAiCountriesList]);
  const fetchUserToEdit = async () => {
    const { data, status } = await searchUserByIdApi(id);
    if (status === 200) {
      setUser(data.message[0]);
    }
  };
  const handleChangeInput = (event) => {
    const { value, name } = event.target;

    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGetCountries = async () => {
    try {
      const { data, status } = await getCountries();
      if (status !== 200) return;
      const filterdCountries = data.filter((count) =>
        DebAiCountriesList.find((name) => {
          return name === count?.name?.common;
        })
      );

      setCountryList(filterdCountries);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckBoxChange = (e) => {
    setUserData((prev) => ({
      ...prev,
      verified: e.target.checked,
    }));
  };
  const handleEdit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const { status, data } = await updateUserapi(id, {
        ...userData,
        endDate,
      });
      if (status === 200) {
        open({ type: "success", text: "user updated successfully" });
        setUser(data.message);
        setUpdating(false);
      } else {
        throw "something went wrong";
      }
    } catch (error) {
      setUpdating(false);
      open({ type: "error", text: "failed to update user" });
    }
  };

  return (
    <form onSubmit={handleEdit} className={styles.edit_user_form}>
      <div className={styles.input_item}>
        <input
          type="text"
          name="firstName"
          id=""
          placeholder="firstName"
          onChange={handleChangeInput}
          value={userData.firstName}
        />
      </div>
      <div className={styles.input_item}>
        <input
          type="text"
          name="lastName"
          id=""
          placeholder="lastName"
          onChange={handleChangeInput}
          value={userData.lastName}
        />
      </div>
      <div className={styles.input_item}>
        <input
          type="email"
          name="email"
          id=""
          placeholder="email address"
          onChange={handleChangeInput}
          value={userData.email}
        />
      </div>
      <div className={styles.input_item}>
        <input
          type="number"
          name="points"
          id=""
          placeholder="points"
          onChange={handleChangeInput}
          value={userData.points}
        />
      </div>
      <div className={styles.input_item}>
        <select
          name="country"
          value={userData.country}
          onChange={handleChangeInput}
        >
          {countryList.map((country) => (
            <option value={country.name.common}>{country.name.common}</option>
          ))}
        </select>
      </div>
      {user?.subscription?.payment_type && (
        <div className={styles.input_item}>
          <input
            type="text"
            disabled
            value={` Payment Type - ${user?.subscription?.payment_type}`}
          />
        </div>
      )}
      <div className={styles.normal_item}>
        <label htmlFor="" className={styles.label}>
          Verified
        </label>
        <input
          type="checkbox"
          name="verified"
          id=""
          checked={userData.verified}
          className={styles.check_box_input}
          onChange={handleCheckBoxChange}
        />
      </div>
      <div className={styles.normal_item}>
        <label htmlFor="" className={styles.label}>
          Subscription Status
        </label>
        <input
          type="checkbox"
          name="isActive"
          id=""
          disabled
          checked={user?.subscription?.active}
          className={styles.check_box_input}
          onChange={handleCheckBoxChange}
        />
      </div>

      <div>
        <label htmlFor="" className={styles.label}>
          Select subscription end date
        </label>
        <DatePicker
          selected={endDate}
          className={styles.date_picker}
          onChange={(date) => setEndDate(date)} // Update state when date is selected
          showTimeSelect // Enable time picker
          timeFormat="HH:mm" // Format for the time
          timeIntervals={1} // Set the interval for time selection (e.g., every 1 minutes)
          dateFormat="MMMM d, yyyy h:mm aa" // Format for date and time display
          timeCaption="Time" // Label for the time picker
          placeholderText="Select a date and time" // Optional placeholder
        />
      </div>
      <button
        type="submit"
        disabled={updating}
        className={styles.update_button}
      >
        {updating ? "UPDATING..." : "UPDATE"}
      </button>
    </form>
  );
};

export default EditUserForm;
