import { useEffect, useState } from "react";
import { getFlag } from "../../../utils/services";
import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Button } from "flowbite-react";
import { useSelector } from "react-redux";
import { deleteCoachApi } from "../../../utils/Api";
import useAlert from "../../../hook/useAlert";
import CalendlyModal from "../../../Layouts/modal/Calendenly/CalendlyModal";
import { ConfirmationModal } from "../../modal/ConfirmationModal";

const CoachItem = ({ data, format, onSuccess }) => {
  const [flag, setFlag] = useState("");
  const { data: userData } = useSelector((state) => state.user);
  const [isDeleting, setIsDeleting] = useState(false);
  const { open } = useAlert();

  useEffect(() => {
    async function getFlagfunc() {
      try {
        setFlag(await getFlag(data.country));
      } catch (error) {
        console.log("error", error);
      }
    }
    getFlagfunc();
  }, [data]);

  const handleDeleteCoach = async (id) => {
    try {
      setIsDeleting(true);
      await deleteCoachApi(id);
      setIsDeleting(false);
      onSuccess();
      open({ type: "success", text: "Coach deleted successfully" });
    } catch (error) {
      setIsDeleting(false);
      open({ type: "error", text: "Failed to delete coach" });
      console.log(error);
    }
  };

  return (
    <Card maxW="320px" variant="elevated">
      <CardBody>
        <Avatar size="lg" name={data.name} src={data.image} />
        <Stack mt="2" spacing="2">
          <Stack direction="row" align="center" spacing="2">
            <Heading size="md">{data.name}</Heading>
            {flag && (
              <Image
                src={flag}
                alt={`${data.country} flag`}
                boxSize="24px"
                objectFit="cover"
              />
            )}
          </Stack>
          <Text>{data.about?.substring(0, 100)}</Text>
        </Stack>
      </CardBody>
      <CardFooter justify="flex-end" className="flex justify-end gap-x-2">
        <CalendlyModal flag={flag} coach={data} format={format}>
          <Button className="border border-stone-200" variant="outline" color={"primary"} mr="2">
            {userData?.isAdmin ? "Update Profile" : "View Profile"}
          </Button>
        </CalendlyModal>
        {userData?.isAdmin && (
          <ConfirmationModal
            confirmationText="Are you sure you want to delete this coach?"
            loading={isDeleting}
            onCancel={() => {}}
            onConfirm={() => handleDeleteCoach(data._id)}
          >
            <Button color={"failure"}>
              {isDeleting ? "Deleting..." : "Delete Coach"}
            </Button>
          </ConfirmationModal>
        )}
      </CardFooter>
    </Card>
  );
};

export default CoachItem;
