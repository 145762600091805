import {
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box,
} from "@chakra-ui/react";

const steps = [
  { title: "Basic", description: "" },
  { title: "Teams", description: "" },
  { title: "Time Format", description: "" },
  { title: "Confirm", description: "" },
];

export function StepperComp({ active }) {
  return (
    <Stepper size="lg" colorScheme="yellow" index={active} mb={"2rem"}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator className="stepIcon">
            <StepStatus complete={`✔`} incomplete={`⚙`} active={`⚙`} />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle style={{ fontSize: "13px", color: "gray" }}>
              {step.title}
            </StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
}
