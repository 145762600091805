"use client";

import { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  VStack,
  FormControl,
  FormLabel,
  Avatar,
  useDisclosure,
  Box,
  Textarea,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import useUploadFile from "../../hook/useUploadFile";
import { createCoachApi } from "../../utils/Api";
import useAlert from "../../hook/useAlert";

export function CreateCoachModal({ children, type, onSuccess }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreating, setIsCreating] = useState(false);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [desc, setDesc] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  // Bank details state
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const fileInputRef = useRef();
  const { uploadFile } = useUploadFile();
  const { open } = useAlert();

  const handleCreate = async () => {
    try {
      if (isCreating) return;
      setIsCreating(true);

      const payload = {
        name: name,
        country: country,
        about: desc,
        image: "",
        type,
        accountHolderName: accountHolderName,
        bankName: bankName,
        ifsc: ifsc,
        accountNumber: accountNumber,
      };

      if (profilePic) {
        const image_url = await uploadFile(profilePic);
        payload.image = image_url;
      }

      await createCoachApi(payload);
      open({ type: "success", text: "Coach created successfully" });

      // Reset form
      setName("");
      setCountry("");
      setDesc("");
      setProfilePic(null);
      setAccountHolderName("");
      setBankName("");
      setIfsc("");
      setAccountNumber("");
      setIsCreating(false);
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error creating coach:", error);
      setIsCreating(false);
      open({
        type: "error",
        text: "Error creating coach",
      });
    }
  };

  const handleCancel = () => {
    setName("");
    setCountry("");
    setDesc("");
    setProfilePic(null);
    setAccountHolderName("");
    setBankName("");
    setIfsc("");
    setAccountNumber("");
    setIsCreating(false);
    onClose();
  };

  const handleChangeFile = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setProfilePic(files[0]);
    }
  };

  return (
    <>
      <span className="cursor-pointer" onClick={onOpen}>
        {children}
      </span>

      <Modal isOpen={isOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent maxW="4xl">
          {" "}
          {/* Increased modal width */}
          <ModalHeader>Create New Coach</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Box position="relative">
                <input
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleChangeFile}
                  type="file"
                  name="profilePic"
                  accept="image/*"
                />
                <Avatar
                  onClick={() => fileInputRef.current?.click()}
                  size="xl"
                  src={profilePic ? URL.createObjectURL(profilePic) : undefined}
                  name={name || "New Coach"}
                />
              </Box>

              <Grid templateColumns="repeat(2, 1fr)" gap={4} w="full">
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      variant="filled"
                      placeholder="Enter coach name"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>Country</FormLabel>
                    <Input
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      variant="filled"
                      placeholder="Enter country code (e.g., US)"
                    />
                  </FormControl>
                </GridItem>
              </Grid>

              <FormControl isRequired>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  variant="filled"
                  placeholder="Enter coach description"
                  rows={3}
                />
              </FormControl>

              {/* Bank Details Section */}
              <Grid templateColumns="repeat(2, 1fr)" gap={4} w="full">
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>Account Holder Name</FormLabel>
                    <Input
                      value={accountHolderName}
                      onChange={(e) => setAccountHolderName(e.target.value)}
                      variant="filled"
                      placeholder="Enter account holder name"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>Bank Name</FormLabel>
                    <Input
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      variant="filled"
                      placeholder="Enter bank name"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>IFSC Code</FormLabel>
                    <Input
                      value={ifsc}
                      onChange={(e) => setIfsc(e.target.value)}
                      variant="filled"
                      placeholder="Enter IFSC code"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>Account Number</FormLabel>
                    <Input
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      variant="filled"
                      placeholder="Enter account number"
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              disabled={
                isCreating ||
                !name ||
                !country ||
                !desc ||
                !accountHolderName ||
                !bankName ||
                !ifsc ||
                !accountNumber
              }
              leftIcon={<BiPlus />}
              colorScheme="blue"
              onClick={handleCreate}
            >
              {isCreating ? "Creating..." : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateCoachModal;
