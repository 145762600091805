import React from "react";

const WeeklyDebateResults = () => {
  return (
    <div className="flex flex-col gap-y-3 flex-[4]   rounded-md">
      <div className="px-3">
        <h1 className="font-bold text-xl ">TOURNAMENT CHAMPIONS </h1>
      </div>
      <div className="flex flex-col gap-y-7">
        <div className="flex gap-y-2 flex-col shadow-md rounded-md  p-3">
          <p className="text-sm font-medium text-gray-600 underline">
            25th of May , 2024 11:00 AM
          </p>
          <div className="flex flex-col gap-y-2 border-b border-gray-100 py-3 ">
            <div className="flex flex-col gap-y-1">
              <div className="line-clamp-2 font-medium">
                1. Zoo should be banned
              </div>
              <div className=" rounded-md w-fit text-[12px] font-semibold ">
                AMY AMERSON
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2   py-3 ">
            <div className="line-clamp-2">
              2. Single gender school should not exist . Single gender school
              should not exist . Single gender school should not exist.
            </div>
            <div className="rounded-md font-semibold   w-fit text-[12px] uppercase ">
              Baby Johnson
            </div>
          </div>
        </div>
        <div className="flex gap-y-2 flex-col shadow-md p-3 rounded-m">
          <p className="text-sm font-medium text-gray-600 underline">
            17th of May , 2024 03:32 PM
          </p>
          <div>
            <div className="flex flex-col gap-y-2 border-b  border-gray-100  py-3">
              <div className="flex flex-col gap-y-1">
                <div>1. Zoo should be banned</div>
                <div className=" rounded-md font-semibold  w-fit text-[12px]">
                  AMY AMERSON
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-1  py-3">
              <div className="line-clamp-2">
                2. Single gender school should not exist . Single gender school
                should not exist . Single gender school should not exist.
              </div>
              <div className=" rounded-md font-semibold   w-fit text-[12px] uppercase ">
                Baby Johnson
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeeklyDebateResults;
