import styles from "./DebateCompletionUi.module.css";
import { useEffect, useState } from "react";
import MysteryBox from "../MysteryBox/MysteryBox";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDebateByIdApi } from "../../../utils/Api";
import { getMyTeam } from "../../../utils/services";
import { Enums } from "../../../redux/action/actionTypes/Enumss";
import { debateFormatEnum } from "../../../utils/enum";

const DebateCompletionUi = () => {
  // State declarations
  const [activeDebate, setActiveDebate] = useState(null);
  const [debateResult, setDebateResult] = useState("");
  const [isAudience, setIsAudience] = useState(true);

  // Hooks
  const { data: currentUser } = useSelector((state) => state.user);
  const { debateId } = useParams();

  // Fetch debate data when debateId changes
  useEffect(() => {
    if (!debateId) return;
    fetchDebateById();
  }, [debateId]);

  // Update debate result when activeDebate or currentUser changes
  useEffect(() => {
    if (!currentUser) {
      return setDebateResult("AUDIENCE");
    }

    if (!activeDebate) return;

    const { winner, teams, type, results, judgeType } = activeDebate;
    const myTeamName = getMyTeam(teams, currentUser?._id)?.name;

    if (judgeType === Enums.NO_WINNING_AI_JUDGE) {
      return setDebateResult(Enums.NO_WINNING_AI_JUDGE);
    }

    if (winner === Enums.INVALID || !winner) {
      return setDebateResult(Enums.INVALID);
    }

    // Determine if user is audience
    if (!myTeamName) {
      return setDebateResult("AUDIENCE");
    }

    setIsAudience(false);

    // Handle British Parliamentary format
    if (type === debateFormatEnum["British Parliamentary"] && results) {
      let position_of_team;
      Object.entries(results).forEach(([position, team]) => {
        if (team === myTeamName) {
          position_of_team = position;
        }
      });
      return setDebateResult(position_of_team);
    }

    // Handle standard debate outcomes
    if (myTeamName?.toLowerCase() === winner?.toLowerCase()) {
      return setDebateResult(Enums.WON);
    }

    if (Enums.MATCH_TIED === winner) {
      return setDebateResult(Enums.TIED);
    }

    return setDebateResult(Enums.LOSE);
  }, [activeDebate, currentUser]);

  // Fetch debate data function
  const fetchDebateById = async () => {
    try {
      const { data, status } = await getDebateByIdApi(debateId);
      if (status === 200) {
        setActiveDebate(data.message[0]);
      }
    } catch (error) {
      // Error handling intentionally left empty as per original code
    }
  };

  // Render
  return (
    <div className={styles.finishModalContainer}>
      <div className={styles.modalBodyContent}>
        <MysteryBox
          debateResult={debateResult}
          activeDebate={activeDebate}
          isAudience={isAudience}
        />
      </div>
    </div>
  );
};

export default DebateCompletionUi;
