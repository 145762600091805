import React from "react";
import DebateCard from "../../components/DebateRoom/DebateCard/DebateCard";
import DebateSkeleton from "../Skeleton/DebateCardStn/DebateCardStn";

const MyDebateBox = ({ debates }) => {
  return (
    <div className="my_profile_debate_box_Wrappper">
      {debates ? (
        debates.length > 0 ? (
          debates.map((debate) => (
            <DebateCard key={debate._id} debate={debate} />
          ))
        ) : (
          <h2>No Debate Found</h2>
        )
      ) : (
        <DebateSkeleton />
      )}
    </div>
  );
};

export default MyDebateBox;
