import axios from "axios";
import { axiosInstance } from "./axios";

//USER API ENDPOINTS
export const RegisterUserApi = (data) =>
  axiosInstance.post("/auth/register", data);
export const LoginUserApi = (data) => axiosInstance.post("/auth/login", data);
export const getLoggedInUserApi = () =>
  axiosInstance.get("/user/getLoggedInUser");
export const getOrCreateUserApi = (payload) =>
  axiosInstance.post("/auth/getOrCreateUser", payload);
export const logoutApi = () => axiosInstance.post("/auth/logout");
export const searchUserByNames = (username) =>
  axiosInstance.get(`/user/search?search_query=${username}`);
export const searchUserByIdApi = (userId) =>
  axiosInstance.get(`/user/search?userId=${userId}`);
export const getUserChartDataApi = (year) =>
  axiosInstance.get(`/user/analytics?year=${year}`);
export const getUserStatsApi = (month) =>
  axiosInstance.get(`/user/stats?month=${month}`);
export const getDebateByTimeApi = (time) =>
  axiosInstance.get(`/debate?${time}=true`);
export const deleteUserApi = (userId) =>
  axiosInstance.delete(`/user/${userId}`);
export const getAllDebaiUsersApi = () => axiosInstance.get("/user/search");
export const getTopUsersApi = () => axiosInstance.get("/user/leadingDebators");
export const emailConfirmationApi = (code) =>
  axiosInstance.post(`/auth/email_confirmation/${code}`);
export const resetpasswordApi = (token, data) =>
  axiosInstance.post(`/auth/resetpassword/${token}`, data);
export const checkIfTokenIsValidApi = (token) =>
  axiosInstance.post(`/auth/tokenvalid/${token}`);
export const resentLinkToResetPasswordApi = (email) =>
  axiosInstance.post(`/auth/sentLinkToResetPassword`, { email });
export const sentEmailToVerifyEmailApi = (email) =>
  axiosInstance.post(`/auth/sentEmailToVerifyEmail`, { email });
export const getDebaiCoachesApi = () => axiosInstance.get(`/user/coaches`);

// DEBATE API ENDPOINTS
export const getIsPassocodeUniqueApi = (passcode) =>
  axiosInstance.post("/debate/checkPasscode", { passcode });

export const createDebateApi = (data) => axiosInstance.post("/debate", data);

export const getDebateByIdApi = (debateId) =>
  axiosInstance.get(`/debate?_id=${debateId}`);
export const getDebateByPassocde = (passcode) =>
  axiosInstance.get(`/debate?passcode=${passcode}`);
export const getCurrentDebateApi = () =>
  axiosInstance.get(`/debate?live=${true}`);
export const getAllDebateApi = () =>
  axiosInstance.get(`/debate?upcoming=${true}`);
export const getDebateOfUserApi = (userId) =>
  axiosInstance.get(`/debate?admin=${userId}`);
export const getAgoraTokenApi = ({
  channelName,
  role,
  tokentype,
  uid,
  expiry,
}) =>
  axiosInstance.get(
    `/auth/rte/${channelName}/${role}/${tokentype}/${uid}/?expiry=${expiry}`
  );
export const deleteDebateApi = (debateId) =>
  axiosInstance.delete(`/debate/${debateId}`);
export const getUsersDebateCountApi = (userId) =>
  axiosInstance.get(`/debate/counts/${userId}`);
export const joinParticipantApi = (debateId, data) =>
  axiosInstance.post(`/debate/joinParticipant/${debateId}`, data);
export const removeParticipantApi = (debateId, data) =>
  axiosInstance.post(`/debate/removeParticipant/${debateId}`, data);
export const getSingleDebateApi = (debateId) =>
  axiosInstance.get(`/debate/singleDebate/${debateId}`);
export const endDebateForNoJudegeApi = (debateId) =>
  axiosInstance.get(`/debate/debateEnd/${debateId}?winType=false`);
// subscription api
export const getPrices = () => axiosInstance.get(`/user/prices`);
export const subscribeWithCoupon = (data) =>
  axiosInstance.post(`/user/subscribeWithCoupon`, data);
export const setStripeSession = (data) =>
  axiosInstance.post(`/user/setStripeSession`, data);
export const cancelSubscriptionApi = (data) =>
  axiosInstance.post(`/user/cancelSubscription`, data);

export const createStripeSubsrption = (data) =>
  axiosInstance.post(`/user/createStripeSubsrption`, data);

// chat bot api
export const getBotMessageApi = (data) => axiosInstance.post("/chatbot", data);
export const updateUserapi = (userId, data) =>
  axiosInstance.put(`/user/${userId}`, data);

// live chat api
export const createChatApi = (data) => axiosInstance.post("/chat/create", data);
export const findChatApi = (debateId) =>
  axiosInstance.get(`/chat/find?debate=${debateId}`);

// vote and unvote

export const voteTeamApi = (data) => axiosInstance.post("/debate/vote", data);
export const unVoteTeamApi = (data) =>
  axiosInstance.post("/debate/unVote", data);
export const voteAndUnvoteTeamApi = (data) =>
  axiosInstance.post("/debate/voteAndUnvote", data);

// debates
export const getPastDebatesOfCurrentUserApi = (data) =>
  axiosInstance.get(`/debate/${data.userId}?future=${data.future}`);
export const getFutureDebatesOfCurrentUserApi = (data) =>
  axiosInstance.get(`/debate/${data.userId}?future=${data.future}`);

export const getInvitationDebatesOfCurrentUserApi = (data) =>
  axiosInstance.get(`/debate/${data.userId}?invitation=${data.invitation}`);
export const updateDebateApi = (debateId, data) =>
  axiosInstance.put(`/debate/${debateId}`, data);
export const addAvatarEquipedMembersInDebate = (debateId, userId) =>
  axiosInstance.put(
    `/debate/addAvatarEquipedMembersInDebate/${debateId}/${userId}`
  );
export const finishDebateApi = (debateId, data) =>
  axiosInstance.post(`/debate/debateEnd/${debateId}`, data);
export const getDebateStatsApi = (month) =>
  axiosInstance.get(`/debate/stats?month=${month}`);
//MOTION API
export const getAllMotionCategoryApi = (group) =>
  axiosInstance.get(`/motion/allcats?group=${group}`);
export const getMotionOfType = (typeId, group) =>
  axiosInstance.get(`/motion/alltypes?typeId=${typeId}&group=${group}`);
export const addNewMotionApi = (data) =>
  axiosInstance.post(`/motion/addmotion`, data);
export const addCommentOnMotionApi = (motionId, data) =>
  axiosInstance.post(`/motion/comment/${motionId}`, data);
export const addNewMotionCatApi = (data) =>
  axiosInstance.post(`/motion/addcat`, data);
export const deltedCatMotionApi = (motionCatId) =>
  axiosInstance.delete(`/motion/cat/${motionCatId}`);
export const uploadMotionFileApi = (file, options) =>
  axiosInstance.post(`/motion/file`, file, options);
// export const getMotionCategoriesForAll=()=>a
export const deleteMotionTopicApi = (motionId) =>
  axiosInstance.delete(`/motion/topic/${motionId}`);

//info
export const debaiInfoApi = () => axiosInstance.get("/info");
export const updateWebsiteInfoApi = (data) => axiosInstance.put("/info", data);

// live  judge tournament

export const createLiveJudgeTournamentApi = (payload) =>
  axiosInstance.post("/judge-tournament/create", payload);
export const getLiveTournamentListApi = () =>
  axiosInstance.get("/judge-tournament/list");
export const getJudgeDebateById = (id) =>
  axiosInstance.get(`/judge-tournament/${id}`);

export const participateToTournamentApi = (payload) =>
  axiosInstance.post("/judge-tournament/participate", payload);
// GET COUNTRIES API
export const getCountries = () =>
  axios.get("https://restcountries.com/v3.1/all");
// stripe
export const getAccountBalanceApi = () => axiosInstance.get("/payment/balance");

// coach api
// Create a new coach
export const createCoachApi = (data) =>
  axiosInstance.post("/coach/create", data);

// Get all coaches
export const getCoachesApi = () => axiosInstance.get("/coach/");

// Get coaches by type (senior or youth)
export const getCoachesByTypeApi = (type) =>
  axiosInstance.get(`/coach/type/${type}`);

// Get a single coach by ID
export const getCoachByIdApi = (coachId) =>
  axiosInstance.get(`/coach/${coachId}`);

// Update a coach by ID
export const updateCoachApi = (coachId, data) =>
  axiosInstance.put(`/coach/${coachId}`, data);

// Delete a coach by ID
export const deleteCoachApi = (coachId) =>
  axiosInstance.delete(`/coach/${coachId}`);
