import React from "react";
import TournamentTeam from "./TournamentTeam";

const AddTournamentParticipants = ({
  motionIndex,
  teams,
  setSearchParticipant,
  handleSelectParticipants,
  handleRemoveParticipant,
}) => {
  return (
    <div className="w-full  grid grid-cols-2 gap-x-2 gap-y-6 flex-wrap">
      {teams.map((team, index) => (
        <TournamentTeam
          handleSelectParticipants={handleSelectParticipants}
          setSearchParticipant={setSearchParticipant}
          key={`${motionIndex}-${index}`}
          index={`${motionIndex}-${index}`}
          handleRemoveParticipant={handleRemoveParticipant}
          team={team}
        />
      ))}
    </div>
  );
};

export default AddTournamentParticipants;
