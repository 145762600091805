"use client";

import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export function ConfirmationModal({
  loading,
  children,
  confirmationText = "Are you sure you want to proceed?",
  onConfirm,
  onCancel,
}) {
  const [openModal, setOpenModal] = useState(false);

  const handleConfirm = async () => {
    try {
      await onConfirm(); // Execute the passed confirmation function
      setOpenModal(false); // Close modal after successful confirmation
    } catch (error) {
      console.log("Confirmation error:", error);
      // You might want to add error handling here
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel(); // Execute the passed cancel function if provided
    }
    setOpenModal(false); // Close modal
  };

  return (
    <>
      <span className="cursor-pointer" onClick={() => setOpenModal(true)}>
        {children}
      </span>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {confirmationText}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                color="success"
                disabled={loading}
                onClick={handleConfirm}
              >
                {loading ? "Loading..." : "Confirm"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
