import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  RegisterUserApi,
  getCountries,
  getOrCreateUserApi,
} from "../../../utils/Api";
import "../Auth.css";
import SelectCountry from "../../../Layouts/popovers/selectCountry/SelectCountry";
import { DebAiCountriesList } from "../../../utils/data";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { setLoggedInUserDataToLS } from "../../../utils/services";
import useIsIOS from "../../../hook/useIsIOS";
import { actionCreators } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
const clientId = process.env.REACT_APP_CLIENT_ID;
const Signup = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    country: "",
    others: {
      missingFields: [],
      error: "",
    },
  });
  const dispatch = useDispatch();
  const { SetRefreshNow } = bindActionCreators(actionCreators, dispatch);

  const isIOS = useIsIOS();
  const navigate = useNavigate();
  const toast = useToast();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [allTimeZone, setAllTimeZone] = useState([]);
  const handleGetTimeZone = async () => {
    const { data, status } = await axios.get(
      "http://api.timezonedb.com/v2.1/list-time-zone?key=AFDXNJS8BF6J&format=json"
    );
    if (status === 200) {
      setAllTimeZone(data.zones);
      setTimeZoneData(data.zones);
    }
  };

  const handleInputChange = (name, value) => {
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    console.log("inside", userDetails.country);
    let timeZoneArr = allTimeZone.filter(
      (t) => t.countryName === userDetails.country
    );
    setTimeZoneData(timeZoneArr);
  }, [userDetails]);

  useEffect(() => {
    handleGetCountries();
  }, [DebAiCountriesList]);

  useEffect(() => {
    handleGetTimeZone();
  }, []);

  const handleGetCountries = async () => {
    try {
      const { data, status } = await getCountries();
      if (status !== 200) return;
      const filterdCountries = data.filter((count) =>
        DebAiCountriesList.find((name) => {
          return name === count?.name?.common;
        })
      );

      setCountries(filterdCountries);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    let fieldsMissing = false;

    // Define required fields excluding lastName
    const requiredFields = {
      firstName: userDetails.firstName,
      email: userDetails.email,
      password: userDetails.password,
      confirmPassword: userDetails.confirmPassword,
      country: userDetails.country,
    };

    // Check only required fields
    for (const key in requiredFields) {
      if (Object.prototype.hasOwnProperty.call(requiredFields, key)) {
        let missingKey = requiredFields[key];
        if (!missingKey) {
          setUserDetails((prev) => ({
            ...prev,
            others: {
              ...prev.others,
              missingFields: [...prev.others.missingFields, key],
            },
          }));
          fieldsMissing = true;
        }
      }
    }

    if (fieldsMissing) {
      handleShowAlert("Please fill all required fields", "error");
      return;
    }

    try {
      setLoading(true);
      let payloadData = {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName || "", // Set empty string if lastName is not provided
        password: userDetails.password,
        email: userDetails.email,
        country: userDetails.country,
      };

      const res = await RegisterUserApi(payloadData);
      setLoading(false);

      if (res.status === 200) {
        navigate("/account/confirmation_email_sent", {
          state: {
            email: userDetails?.email,
          },
        });
      } else {
        throw Error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      const errorMsg = error?.response?.data?.message;
      console.log(errorMsg);
      handleShowAlert(errorMsg ?? "Something went wrong", "error");
    }
  };

  const handleGoogleLogin = () => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/auth/google`, "_blank");
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      await handleRegister();
    }
  };

  const handleShowAlert = (text, type) => {
    toast({
      title: "",
      description: text,
      status: type,
      duration: 5000,
      position: "top",
      isClosable: true,
    });
  };

  const handleCallbackResponse = async (authResult) => {
    const payload = jwtDecode(authResult.credential);
    let payloadData = {
      firstName: payload.given_name,
      lastName: payload.family_name,
      password: payload.given_name, // Consider revising how you handle passwords
      email: payload.email,
    };
    const { data, status } = await getOrCreateUserApi(payloadData);

    if (status === 201) {
      setLoggedInUserDataToLS({ user_id: data.message._id });
      SetRefreshNow(true);
      setTimeout(() => {
        navigate("/");
      }, 100);
    }
  };

  // for iOS Google signup
  useEffect(() => {
    if (isIOS && window.google) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCallbackResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        {
          theme: "outline",
          size: "large",
        }
      );

      window.google.accounts.id.prompt();
    }
  }, [isIOS]);

  return (
    <div className="AuthWrapper">
      <div className="login_main_box">
        <img
          draggable={false}
          className="logo_img"
          src="/images/logo.jpg"
          alt="logo"
        />
        <div className="login_welcome_text">
          {/* <h5 className='welcome_back_text'>Welcome  😋</h5>
        <p className='welcome_secondary_text'>Get started with Debatosour now !! </p> */}
        </div>
        {isIOS ? (
          <div id="signInDiv" style={{ margin: "auto" }}></div>
        ) : (
          <div className="login_with_google_box" onClick={handleGoogleLogin}>
            <img src="/images/google.png" alt="googleIcon" />
            <p>Continue with Google</p>
          </div>
        )}
        <div className="sign_in_with_email_division">
          <div className="left_hr"></div>
          <p>or sign up with email</p>
          <div className="right_hr"></div>
        </div>

        <form className="form_wrapper" onSubmit={handleRegister}>
          <div className="single_item">
            <div className="auth_input_item">
              <input
                className="input_element"
                type="text"
                placeholder="First Name"
                value={userDetails.firstName}
                //
                onChange={(e) => handleInputChange("firstName", e.target.value)}
              />
            </div>
            <div className="auth_input_item">
              <input
                className="input_element"
                type="text"
                placeholder="Last Name"
                value={userDetails.lastName}
                onChange={(e) => handleInputChange("lastName", e.target.value)}
              />
            </div>
          </div>
          <div className="single_item">
            <div className="auth_input_item">
              <input
                className="input_element"
                type="email"
                placeholder="Enter your email address"
                value={userDetails.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </div>
          </div>

          <div className="auth_input_item">
            <SelectCountry
              countries={countries}
              handleInputChange={setUserDetails}
            >
              <div
                className="input_element country_input"
                style={{
                  color: "rgba(128, 128, 128, 0.678)",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <p>
                  {userDetails.country ? userDetails.country : "select country"}
                </p>
              </div>
            </SelectCountry>
            {/* <div className='auth_input_item'>

          <select   className='input_element' onChange={(e)=>handleInputChange("timeZone",e.target.value)} name="timeZone" id="">
            <option value="">Timezone</option>
            {
              timeZoneData.map((c)=>(
                <option value={c.zoneName}>{c.zoneName}</option>
              ))
            }
          </select>

        </div> */}
          </div>

          <div className="single_item">
            <div className="auth_input_item">
              <input
                onKeyDown={handleKeyDown}
                className={`input_element ${
                  userDetails?.password !== userDetails.confirmPassword
                    ? "error_input"
                    : ""
                }`}
                type="password"
                placeholder="Enter your password"
                value={userDetails.password}
                onChange={(e) => handleInputChange("password", e.target.value)}
              />
            </div>
            <div className="auth_input_item">
              <input
                onKeyDown={handleKeyDown}
                className={`input_element ${
                  userDetails?.password !== userDetails.confirmPassword
                    ? "error_input"
                    : ""
                }`}
                type="password"
                placeholder="Confirm password"
                value={userDetails.confirmPassword}
                onChange={(e) =>
                  handleInputChange("confirmPassword", e.target.value)
                }
              />
            </div>
          </div>

          <button className="login_button" type="submit">
            {loading ? "Signing in" : "Sign in"}
          </button>
          <Link to="/login">
            <p className="no_account_text">Already have account </p>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Signup;
