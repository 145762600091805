import React, { useEffect, useState } from "react";
import { getLiveTournamentListApi } from "../../../utils/Api";
import TournamentItem from "./TournamentItem";
const WeeklyDebate = () => {
  const [liveTournaments, setLiveTournaments] = useState([]);

  useEffect(() => {
    fetchLiveTournaments();
  }, []);

  const fetchLiveTournaments = async () => {
    try {
      const { data, status } = await getLiveTournamentListApi();
      if (status === 200) {
        setLiveTournaments(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex flex-col gap-y-5 flex-[8] ">
      <div>
        <h1 className="font-bold text-2xl">DEBATE TOURNAMENTS</h1>
      </div>
      <div className="flex flex-col gap-y-10">
        {liveTournaments?.map((tournament) => (
          <TournamentItem key={tournament.id} tournament={tournament} />
        ))}
      </div>
    </div>
  );
};

export default WeeklyDebate;
