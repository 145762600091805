import AgoraRTC from "agora-rtc-sdk-ng";
import { Rtc_client, Rtm_client } from "../pages/debateRoom/DebateRoom";
import { Enums } from "../redux/action/actionTypes/Enumss";
import {
  finishDebateApi,
  getAgoraTokenApi,
  getCountries,
  joinParticipantApi,
  removeParticipantApi,
  updateDebateApi,
} from "./Api";
import { avatarsTypeData } from "./data";
import moment from "moment";
import { debateFormatEnum } from "./enum";

// import { connection } from "mongoose";

export const getMyTeam = (teams, myUserId) => {
  if (!teams || !myUserId) return;

  return teams.find((team) => team.members.find((mem) => mem._id === myUserId));
};
export const getNextSpeakTeam = (teams, debateStartedTeam, roundShot) => {
  if (!teams || !debateStartedTeam || !roundShot) return;

  let teamsName = teams.map((team) => team.name);
  if (Math.floor(roundShot % 2) === 0) {
    let nextTeam = teamsName.find((team) => team !== debateStartedTeam);
    return nextTeam;
  } else {
    return debateStartedTeam;
  }
};
export const setLoggedInUserDataToLS = (userData) => {
  if (userData) {
    localStorage.setItem("current_user", JSON.stringify(userData));
  }
};

export const getLoggedInUserDataFromLS = () => {
  return JSON.parse(localStorage.getItem("current_user"));
};

export const getNameAndVoteOfTeams = (teams) => {
  const sortedTeams = teams.sort((a, b) => b.vote.length - a.vote.length);

  return sortedTeams.map((team) => ({
    name: team.name,
    vote: team.vote.length,
  }));
};

export const removeLoggedInUserData = () => {
  localStorage.removeItem("user");
};

export const getTimeCountDown = (timeInMs, day, hour, min, sec) => {
  if (timeInMs) {
    const { day, hour, min, sec } = getTimeFromMs(timeInMs);
    return ` ${day ? `${day > 1 ? `${day}days` : `${day}day`} :` : ""}  ${
      hour ? `${hour > 1 ? "hours" : "hour"}:` : ""
    } ${min || hour ? `${min}min :` : ""} ${`${sec}sec`}
      `;
  } else {
    if (day === null || hour === null || min === null || sec === null)
      return null;

    return ` ${day ? `${day > 1 ? `${day}days` : `${day}day`} :` : ""}  ${
      hour ? `${hour > 1 ? `${hour}hours` : `${hour}hour`}  :` : ""
    } ${min || hour ? `${min}min :` : ""} ${`${sec}sec`}
    `;
  }
};

export const getTime = (diff) => {
  let min = Math.floor(diff / (1000 * 60));
  let sec = Math.floor((diff / 1000) % 60);

  return `${min ? `${min} min : ` : ""} ${sec} sec`;
};

export const getTimeFromMs = (startTime) => {
  const currentTime = Date.now();
  const timeDifference = startTime - currentTime;

  if (timeDifference <= 0) {
    return {
      day: 0,
      hour: 0,
      min: 0,
      sec: 0,
    };
  }

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return {
    day: days,
    hour: hours % 24,
    min: minutes % 60,
    sec: seconds % 60,
  };
};

export const getTheVotedTeam = (teams, userId) => {
  const theTeam = teams.find((team) =>
    team.vote?.find((user) => user === userId)
  );
  if (theTeam) {
    return theTeam.name;
  } else {
    return false;
  }
};

export const getNamesofTeam = (teams) => {
  return teams?.map((team) => team.name);
};

export const changeVote = (teams, type, userId, teamsName) => {
  return teams.map((team) => {
    if (team.name === teamsName) {
      if (type === "pull") {
        return { ...team, vote: team.vote.filter((mem) => mem !== userId) };
      } else {
        return { ...team, vote: [...team.vote, userId] };
      }
    } else {
      return team;
    }
  });
};

export function generateRandomNumber() {
  var min = 100000; // Minimum 6-digit number (inclusive)
  var max = 999999; // Maximum 6-digit number (inclusive)

  var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber;
}

export function getMysterAvatar(gameResult) {
  const randomNumber = Math.floor(Math.random() * 100);
  let mysteryAvatar;
  if (gameResult === Enums.WON) {
    if (randomNumber < 25) {
      mysteryAvatar = getRandomAvatar(avatarsTypeData.common, "Common");
    } else if (randomNumber < 50) {
      mysteryAvatar = getRandomAvatar(avatarsTypeData.rare, "Rare");
    } else if (randomNumber < 75) {
      mysteryAvatar = getRandomAvatar(avatarsTypeData.legendary, "Legendary");
    } else {
      mysteryAvatar = getRandomAvatar(avatarsTypeData.epic, "Epic");
    }
  } else if (gameResult === Enums.LOSE || gameResult === Enums.MATCH_TIED) {
    if (randomNumber < 5) {
      mysteryAvatar = getRandomAvatar(avatarsTypeData.legendary, "Legendary");
    } else if (randomNumber < 15) {
      mysteryAvatar = getRandomAvatar(avatarsTypeData.epic, "Epic");
    } else if (randomNumber < 35) {
      mysteryAvatar = getRandomAvatar(avatarsTypeData.rare, "Rare");
    } else {
      mysteryAvatar = getRandomAvatar(avatarsTypeData.common, "Common");
    }
  } else if (gameResult === "1") {
    mysteryAvatar = getRandomAvatar(avatarsTypeData.epic, "Epic");
  } else if (gameResult === "2") {
    mysteryAvatar = getRandomAvatar(avatarsTypeData.legendary, "Legendary");
  } else if (gameResult === "3") {
    mysteryAvatar = getRandomAvatar(avatarsTypeData.rare, "Rare");
  } else if (gameResult === "4") {
    mysteryAvatar = getRandomAvatar(avatarsTypeData.common, "Common");
  }
  return mysteryAvatar;
}

const getRandomAvatar = (avatars, avatarType) => {
  let randomNum = Math.floor(Math.random() * avatars.length);
  return { type: avatarType, avatar: avatars[randomNum] };
};
export const getVoteOfTeam = (debate, teamName) => {
  if (!debate || !teamName) return;
  return debate.teams.find((team) => team.name === teamName)?.vote.length;
};
export const checkIfUserAlreadyExist = (speakers, uid) => {
  return speakers.find((speaker) => speaker?.uid === uid);
};

export const getFlag = (country) => {
  return getCountries().then((res) => {
    let flag = res.data.find(
      (coun) => coun.name?.common?.toLowerCase() === country?.toLowerCase()
    )?.flags?.svg;

    return flag;
  });
};

export const AmIParticipants = (teams, userId) => {
  if (!teams || !userId) return false;

  return teams.some((team) => team.members.some((mem) => mem._id === userId));
};
export const calculateAge = (birthdate) => {
  const birthDate = new Date(birthdate);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  // Adjust age if the birthdate has not occurred yet this year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};

class DebateRoomServices {
  constructor({
    rtmChannelRef,
    open,
    allUsers,
    intervalRef,
    setCoach,
    setSpeakTimeLeft,
    winTeamName,
    setWinTeamName,
    startCoachDecision,
    setStartCoachDecision,
    audienceList,
    setAudienceList,
    setAllusers,
    MicElmRef,
    hasLeftRoom,
    addLiveMessages,
    navigate,
    rtcUid,
    data: user,
    lastApiCallConfig,
    setRoomLoading,
    activeDebateRef,
    debateStateRef,
    setDebateState,
    setActiveMicControlTeam,
    isAudience,
    setRoomMembers,
    setMicMuted,
    debateId,
    RoomMembers,
    audioTracks,
    setActiveSpeakers,
    setRtmChannelAction,
    isLive,
    micMuted,
    AddActiveDebate,
    setMessage,
    showToast,
    activeSpeakers,
    timeRemainingRef,
    otherState,
    setShowResult,
    activeMicControlTeam,
    setResult,
    result,
  }) {
    this.navigate = navigate;
    this.setCoach = setCoach;
    this.rtmChannelRef = rtmChannelRef;
    this.setAllusers = setAllusers;
    this.rtcUid = rtcUid;
    this.currentUser = user;
    this.activeDebate = activeDebateRef;
    this.showToast = showToast;
    this.debateState = debateStateRef;
    this.open = open;
    this.changeDebateState = setDebateState;
    this.changeMicControlTeam = setActiveMicControlTeam;
    this.AddActiveDebate = AddActiveDebate;
    this.setMessage = setMessage;
    this.isAudience = isAudience;
    this.MicElmRef = MicElmRef;
    this.changeRoomMember = setRoomMembers;
    this.debateId = debateId;
    this.RoomMembers = RoomMembers;
    this.audioTracks = audioTracks;
    this.changeActiveSpeakers = setActiveSpeakers;
    this.activeSpeakers = activeSpeakers;
    this.timeRemainingRef = timeRemainingRef;
    this.setMicMuted = setMicMuted;
    this.activeMicControlTeam = activeMicControlTeam;
    this.micMuted = micMuted;
    this.isLive = isLive;
    this.SetRoomLoading = setRoomLoading;
    this.setRtmChannelAction = setRtmChannelAction;
    this.lastApiCallConfig = lastApiCallConfig;
    this.otherState = otherState;
    this.hasLeftRoom = hasLeftRoom;
    this.addLiveMessages = addLiveMessages;
    this.audienceList = audienceList;
    this.setAudienceList = setAudienceList;
    this.setStartCoachDecision = setStartCoachDecision;
    this.startCoachDecision = startCoachDecision;
    this.winTeamName = winTeamName;
    this.setWinTeamName = setWinTeamName;
    this.setSpeakTimeLeft = setSpeakTimeLeft;
    this.intervalRef = intervalRef;
    this.allUsers = allUsers;
    this.setShowResult = setShowResult;
    this.setResult = setResult;
    this.result = result;
  }

  async removeParticipant() {
    if (!this.isAudience && this.currentUser && this.activeDebate.current) {
      try {
        await removeParticipantApi(this.activeDebate?.current?._id, {
          participantId: this.currentUser?._id,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  getMyTeamMethod() {
    return this.activeDebate.current.teams.find((team) =>
      team.members.find((mem) => mem._id === this.currentUser?._id)
    );
  }

  getMemberWithHighUid() {
    let myUid = Number(this.rtcUid);
    return this.RoomMembers.some((mem) => Number(mem.rtcUid) > myUid);
  }

  getWinnerByVote(teams) {
    let winner = null;
    let maxLength = 0;
    let voteCounts = {};
    let matchTied = false;
    let result = {};

    for (const team of teams) {
      if (voteCounts[team.vote.length]) {
        matchTied = true;
        break;
      } else {
        voteCounts[team.vote.length] = true;
      }
    }

    teams.forEach((team) => {
      if (team.vote.length > maxLength) {
        maxLength = team.vote.length;
        winner = team.name;
      }
    });

    // Sort teams based on the length of the vote array in descending order
    teams.sort((a, b) => b.vote.length - a.vote.length);

    // Create a new object with keys representing the length of vote array and values representing team names
    teams.forEach((team, index) => {
      result[index] = team.name;
    });

    //  else get the losers team and the winner team in an separate arrray
    return { winner: matchTied ? Enums.TIED : winner, result };
  }

  getTeamDataByName(teamName) {
    if (!this.activeDebate?.current) return;
    return this.activeDebate?.current.teams.find(
      (team) => team.name === teamName
    );
  }

  async UpdateChannelAttr(key, payload) {
    const { channelId } = this.rtmChannelRef.current;
    try {
      await Rtm_client.addOrUpdateChannelAttributes(channelId, {
        [key]: JSON.stringify(payload),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async updateDebateInDb(updatePayload) {
    try {
      const { status } = await updateDebateApi(
        this.activeDebate?.current?._id,
        {
          ...updatePayload,
        }
      );
      if (status === 200) {
        this.activeDebate.current = {
          ...this.activeDebate.current,
          ...updatePayload,
        };
        this.AddActiveDebate(this.activeDebate);
      }
    } catch (error) {
      console.log("something went wrong while creating a debate", error);
    }
  }
  getTeamName() {
    if (this.activeDebate.current) {
      return this.activeDebate.current.teams.map((team) => team.name);
    }
  }

  async removIntervalFunc() {
    const { removeInterval } = this.otherState;
    if (!removeInterval) return;

    clearInterval(removeInterval?.intervalRef?.current);
    removeInterval.intervalArrRef.current = [];
  }
  async handleUserPublished(user, mediaType) {
    try {
      await Rtc_client.subscribe(user, mediaType);
      if (mediaType === "audio") {
        this.audioTracks.remoteAudioTracks[user.uid] = [user.audioTrack];
        user.audioTrack?.play();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async handleUserLeave(theUser) {
    try {
      delete this.audioTracks.remoteAudioTracks[theUser.uid];
    } catch (err) {
      console.log(err);
    }
  }
  async getChannelAttributeFunc() {
    if (!Rtm_client || !this.rtmChannelRef.current) return;
    try {
      const { channelId } = this.rtmChannelRef.current;
      const attr = await Rtm_client.getChannelAttributes(channelId);
      return attr;
    } catch (error) {
      console.log(error);
    }
  }

  async getChannelDataByFieldName(fieldName) {
    if (!Rtm_client || !this.rtmChannelRef.current) return;
    try {
      const { channelId } = this.rtmChannelRef.current;
      const attr = await Rtm_client.getChannelAttributes(channelId);
      if (attr) {
        let fieldValueInJson = attr[fieldName]?.value;
        let fieldValue = fieldValueInJson ? JSON.parse(fieldValueInJson) : null;
        return fieldValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async LeaveRtmChannel() {
    try {
      await Rtm_client?.logout();
      await this.rtmChannelRef.current?.leave();
    } catch (error) {
      console.log(error);
    }
  }

  async closeTracks() {
    try {
      if (!this.isAudience && this.audioTracks?.localAudioTracks) {
        console.log("debug 3");
        this.audioTracks.localAudioTracks?.stop();
        this.audioTracks.localAudioTracks?.close();
      }
      // await this.addSpeechToChannel();
      await this.LeaveRtmChannel();
      if (Rtc_client) {
        await Rtc_client?.unpublish();
        await Rtc_client?.leave();
      }
    } catch (error) {
      console.log("closing trackings", error);
    }
  }

  getNoOfDebates() {
    if (this.activeDebate?.current) {
      return this.activeDebate?.current.timeFormat?.filter(
        (format) => format.team !== "break" && format.team !== "prepare"
      ).length;
    }
  }

  getCurrentRoundNo() {
    if (this.debateState?.current && this.activeDebate?.current) {
      const currentRound = this.debateState.current.round_shot;
      let roundNo = 0;
      for (let index = 0; index < currentRound; index++) {
        let round = this.activeDebate?.current.timeFormat[index];

        if (round.team !== "break" && round.team !== "prepare") {
          roundNo++;
        }
      }
      return roundNo;
    }
  }

  async getChannelMembers(channel) {
    if (!channel) return;
    try {
      const members = await channel.getMembers();
      const uniqueMember = [...new Set(members)];
      let allMembers = await Promise.all(
        uniqueMember.map(async (memId) => {
          let { name, rtcUid, avatar, isAdmin, id, type, isCoach } =
            await Rtm_client.getUserAttributes(memId, [
              "name",
              "rtcUid",
              "avatar",
              "isAdmin",
              "id",
              "type",
              "isCoach",
            ]);

          return {
            userId: memId,
            username: name,
            rtcUid,
            avatar,
            isCoach: isCoach === "true",
            isMuted: true,
            id: id,
            type,
            isAdmin: isAdmin === "true",
          };
        })
      );

      // const coaches
      this.setCoach(allMembers.filter((mem) => mem.isCoach === true));

      this.setAllusers(allMembers);
      allMembers = allMembers.filter((mem) => mem.isCoach === false);

      const onlyAudience = allMembers.filter((mem) => {
        return (
          mem.type === "audience" &&
          !AmIParticipants(this.activeDebate?.current?.teams, mem?.id)
        );
      });

      this.setAudienceList(onlyAudience);

      return (allMembers = allMembers.filter((mem) => mem.type !== "audience"));
    } catch (error) {
      console.log(error);
    }
  }

  async handlLastApiCallForTranscript() {
    try {
      const attribute = await this.getChannelAttributeFunc();
      let speech = attribute?.speechText?.value;
      let { _id } = this.activeDebate.current;
      speech = speech ? JSON.parse(speech) : {};

      if (!speech) return;

      for (let key in speech) {
        if (speech.hasOwnProperty(key)) {
          if (!speech[key]) {
            speech[key] = Enums.DID_NOT_SPEAK;
          }
        }
      }
      await finishDebateApi(_id, {
        speech,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleLastSetup() {
    try {
      this.lastApiCallConfig.current.startApiCalled = true;
      await this.createChannelMessage({
        type: "start_last_api_call",
      });
      await this.handlLastApiCallForTranscript();
      this.lastApiCallConfig.current.hasApiCalled = true;
      await this.createChannelMessage({
        type: "last_api_call_success",
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleMemberJoined(MemberId) {
    try {
      let { name, rtcUid, avatar, isAdmin, id, type, isCoach } =
        await Rtm_client.getUserAttributes(MemberId, [
          "name",
          "id",
          "rtcUid",
          "avatar",
          "isAdmin",
          "type",
          "isCoach",
        ]);

      const doesUserExist = this.RoomMembers.find((mem) => mem.id === id);

      if (!name) return;

      if (isCoach === "true") {
        this.setCoach((prev) => {
          return [
            ...prev,
            {
              userId: MemberId,
              username: name,
              rtcUid,
              avatar,
              isCoach,
              isMuted: true,
              id,
              isAdmin: Boolean(isAdmin),
            },
          ];
        });

        return;
      }
      if (type === "audience" && isCoach === "false") {
        this.setAudienceList((prev) => [
          ...prev,
          {
            userId: MemberId,
            username: name,
            rtcUid,
            avatar,
            isCoach,
            isMuted: true,
            id,
            isAdmin: Boolean(isAdmin),
          },
        ]);
        return;
      }
      if (!doesUserExist) {
        this.changeRoomMember((mem) => [
          ...mem,
          {
            userId: MemberId,
            username: name,
            rtcUid,
            avatar,
            isCoach,
            isMuted: true,
            id,
            isAdmin: Boolean(isAdmin),
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async addParticipant() {
    if (this.isAudience) return;
    try {
      await joinParticipantApi(this.activeDebate?.current._id, {
        participantId: this.currentUser?._id,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async handleMemberLeft(MemberId) {
    //

    this.changeRoomMember((mem) => mem.filter((m) => m.userId !== MemberId));
    this.setCoach((prev) => prev.filter((m) => m.userId !== MemberId));
    this.setAudienceList((prev) => prev.filter((m) => m.userId !== MemberId));
  }
  async handleChannelMessage(message) {
    const data = JSON.parse(message.text);

    switch (data.type) {
      case "live_chat":
        delete data.type;
        this.addLiveMessages(data);
        break;

      case "live_vote":
        delete data.type;
        this.activeDebate.current = data;
        this.AddActiveDebate(this.activeDebate);
        break;

      case "last_api_call_success":
        this.lastApiCallConfig.current.hasApiCalled = true;
        break;

      case "debate_start":
        delete data.type;
        this.activeDebate.current = {
          ...this.activeDebate.current,
          timeFormat: data.timeFormat,
          state: data,
        };
        this.AddActiveDebate(this.activeDebate);
        this.changeDebateState(data);
        const diff = data.endAt - Date.now();
        this.setSpeakTimeLeft(diff);
        this.handleTimer(data);

        break;

      case "start_last_api_call":
        this.lastApiCallConfig.current.startApiCalled = true;
        break;

      case "interval_finish":
        delete data.type;
        {
          const { rounds } = data;
          this.changeDebateState(rounds);
          break;
        }

      case "winnerResult":
        const { teamName, result } = data;

        if (!this.winTeamName) {
          this.setWinTeamName(teamName);
          this.setResult(result);
          this.setShowResult(true);
        }
        break;

      default:
        break;
    }
  }
  async InitRTM({ token }) {
    try {
      let rtcUid = this.rtcUid.toString();
      await Rtm_client.login({ uid: rtcUid, token });

      if (!this.currentUser || this.isAudience) {
        const name = this.currentUser?.firstName ?? "audience";
        const avatar = this.currentUser?.avatar ?? "audience";
        const id = this.currentUser?._id ?? "audience";
        await Rtm_client.addOrUpdateLocalUserAttributes({
          name: name,
          rtcUid: rtcUid,
          avatar: avatar,
          id: id,
          type: "audience",
          mic: "muted",
          isCoach: "false",
        });
      } else {
        const { _id, avatar, firstName, lastName, isCoach } = this.currentUser;
        const {
          admin: { _id: adminId },
        } = this.activeDebate?.current;
        let isAdmin = _id === adminId;
        await Rtm_client.addOrUpdateLocalUserAttributes({
          name: `${firstName} ${lastName}`,
          rtcUid: rtcUid,
          avatar: avatar,
          isCoach: isCoach ? "true" : "false",
          id: _id,
          type: "host",
          isAdmin: `${isAdmin}`,
          mic: "muted",
        });
        await this.addParticipant();
      }
      const channel = Rtm_client.createChannel(this.debateId);

      channel.on("MemberJoined", (memId) => this.handleMemberJoined(memId));
      channel.on("MemberLeft", (memId) => this.handleMemberLeft(memId));
      channel.on("ChannelMessage", (message) =>
        this.handleChannelMessage(message)
      );
      this.rtmChannelRef.current = channel;
      await channel.join();
      // this.setRtmChannelAction(channel)
      this.setChannelMember(channel);
    } catch (error) {
      console.log("rtm init ", error);
    }
  }

  async setChannelMember(channel) {
    if (!channel) return;
    try {
      const allMembers = await this.getChannelMembers(channel);
      this.changeRoomMember(allMembers);
    } catch (error) {
      console.log(error);
    }
  }
  async initRTC(token, audioStream) {
    try {
      await Rtc_client.join(
        process.env.REACT_APP_AGORA_APP_ID,
        this.debateId,
        token,
        this.rtcUid
      );
      Rtc_client.on("user-published", (user, mediaType) =>
        this.handleUserPublished(user, mediaType)
      );
      Rtc_client.on("user-left", (user) => this.handleUserLeave(user));
      if (!this.isAudience && audioStream) {
        const localAudioTrack = AgoraRTC.createCustomAudioTrack({
          mediaStreamTrack: audioStream.getAudioTracks()[0],
        });
        this.audioTracks.localAudioTracks = localAudioTrack;
        this.audioTracks.localAudioTracks.setMuted(true);
        await Rtc_client.publish(this.audioTracks.localAudioTracks);

        // await Rtc_client.publish(this.audioTracks.localAudioTracks);
        // this.audioTracks.localAudioTracks =
        //   await AgoraRTC.createMicrophoneAudioTrack();
      }
      await this.initVolumeIndicator();
    } catch (error) {
      console.log(error);
    }
  }
  async initVolumeIndicator() {
    //1
    AgoraRTC.setParameter("AUDIO_VOLUME_INDICATION_INTERVAL", 2000);
    Rtc_client.enableAudioVolumeIndicator();

    //2
    Rtc_client.on("volume-indicator", (volumes) => {
      this.changeActiveSpeakers(volumes);
    });
  }

  async getAgoraToken(audioStream) {
    try {
      this.SetRoomLoading(true);
      const res = await getAgoraTokenApi({
        channelName: this.debateId,
        role: "publisher",
        uid: this.rtcUid,
        tokentype: "1000",
        expiry: 86400,
      });
      if (res.status === 200) {
        let { rtcToken, rtmToken } = res.data;
        await this.initRTC(rtcToken, audioStream);
        await this.InitRTM({ rtmToken });
        this.SetRoomLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async createChannelMessage(message) {
    if (!this.rtmChannelRef?.current) return;

    try {
      await this.rtmChannelRef?.current.sendMessage({
        text: JSON.stringify(message),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleMicTogggle() {
    try {
      if (this.micMuted) {
        await this.openMic();
      } else {
        await this.closeMic();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async addSpeechToChannel(newSpeech) {
    try {
      const speech = newSpeech;
      if (
        !this.currentUser ||
        this.activeDebate.current?.judgeType !== Enums.AIJUDGE ||
        !speech
      )
        return;

      const attr = await this.getChannelAttributeFunc();
      let speechText = attr?.speechText?.value;
      const thePast = speechText ? JSON.parse(speechText) : {};
      const myTeam = this.getMyTeamMethod().name;
      let teamSpeech;
      if (thePast[myTeam]) {
        teamSpeech = thePast[myTeam];
        teamSpeech = `${teamSpeech} ${speech}`;
      } else {
        teamSpeech = speech;
      }
      let newArguments = {
        ...thePast,
        [myTeam]: teamSpeech,
      };

      await this.updateDebateInDb({ arguments: newArguments });
      await this.UpdateChannelAttr("speechText", newArguments);
    } catch (error) {
      console.log(error.message);
    }
  }
  // handle coach decision start

  async handleCoachDecision() {
    await this.UpdateChannelAttr(Enums.COACH_JUDGE_SESSION, "true");
    this.setStartCoachDecision(true);
  }

  // async handleFinalizeCoachDecision() {
  //   if (!this.activeDebate?.current) return;
  //   const { type, _id } = this.activeDebate.current;

  //   const payload = {
  //     winner: this.winTeamName,
  //     result: this.result,
  //   };

  //   const rtmPayload = {
  //     type: "winnerResult",
  //     teamName: this.winTeamName,
  //     result:
  //       type === debateFormatEnum["British Parliamentary"] ? this.result : null,
  //   };

  //   try {
  //     const { status } = await finishDebateApi(_id, payload);
  //     if (status === 200) {
  //       this.setShowResult(true);
  //       this.createChannelMessage(rtmPayload);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async handleDebateState() {
    if (this.activeDebate.current) {
      const { timeFormat, state } = this.activeDebate.current;
      if (!timeFormat || !state?.isStarted) return;
      const format = this.getCurrentFormat(timeFormat);
      if (!format) {
        this.navigate(-1);
        return;
      }

      if (!format) return;

      const { endTime, team, time, count } = format;
      const { startedAt } = state;
      let isInterval = team === "break";
      const debateState = {
        endAt: endTime,
        isInterval,
        startedAt,
        speakTeam: team,
        speakTime: time,
        isStarted: true,
        hasFinished: false,
        round_shot: count,
        changedAt: Date.now(),
        noOfRounds: timeFormat.length,
        both: team === "both",
        timeFormat,
      };

      const diff = debateState.endAt - Date.now();

      this.setSpeakTimeLeft(diff);
      this.handleTimer(debateState);
      this.changeDebateState(debateState);
      this.debateState.current = debateState;
      this.updateStateChange(debateState, false);
      const isCoachSession = await this.getChannelDataByFieldName(
        Enums.COACH_JUDGE_SESSION
      );
      this.setStartCoachDecision(isCoachSession === "true");
    }
  }
  async startDebate() {
    const { newTimeFormat, endAt } = this.createEndTimeFormat();

    const { time, endTime, team, count } = newTimeFormat[0];

    const debateState = {
      endAt: endTime,
      isInterval: false,
      startedAt: Date.now(),
      speakTeam: team,
      speakTime: time,
      isStarted: true,
      hasFinished: false,
      round_shot: count,
      changedAt: Date.now(),
      noOfRounds: newTimeFormat.length,
      both: team === "both",
      timeFormat: newTimeFormat,
    };

    const diff = debateState.endAt - Date.now();
    console.log("test interval ", diff);
    this.setSpeakTimeLeft(diff);

    // change the state
    this.handleTimer(debateState);
    this.changeDebateState(debateState);
    this.debateState.current = debateState;
    const updatedDebate = {
      ...this.activeDebate.current,
      timeFormat: newTimeFormat,
    };
    this.activeDebate.current = updatedDebate;
    this.AddActiveDebate(this.activeDebate);
    this.updateStateChange(debateState, true);
    await this.updateDebateInDb({
      state: debateState,
      timeFormat: newTimeFormat,
      estimatedEndTime: endAt,
    });
    const speechData = this.activeDebate?.current?.teams?.reduce(
      (acc, team) => {
        acc[team.name] = "";
        return acc;
      },
      {}
    );
    await this.UpdateChannelAttr("speechText", speechData);
    // update the database with newformat and endAt
  }

  async updateStateChange(newState, isStarted) {
    if (isStarted) {
      const startDebateStatePayload = { ...newState, type: "debate_start" };
      await this.createChannelMessage(startDebateStatePayload);
    }

    if (!(await this.getMemberWithHighUid()) || isStarted) {
      await this.updateDebateInDb({ state: newState });
      await this.UpdateChannelAttr(Enums.DEBATE_STATE, newState);
    }
  }

  async handleFinishRound() {
    if (this.debateState.current) {
      const { round_shot, timeFormat } = this.debateState.current;
      const nextRound = round_shot + 1;
      if (nextRound > timeFormat.length) {
        this.handleCloseDebate();
      } else {
        this.handleDebateState();
      }
    }
  }

  async handleCloseDebate() {
    if (!this.debateState.current || !this.activeDebate.current) return;

    const { timeFormat, startedAt } = this.debateState?.current;

    let debateRoundsPayload = {
      endAt: timeFormat[timeFormat.length - 1].endTime,
      isInterval: false,
      speakTeam: null,
      speakTime: null,
      isStarted: true,
      hasFinished: true,
      round_shot: timeFormat.length + 1,
      changedAt: Date.now(),
      noOfRounds: timeFormat.length,
      startedAt,
      both: false,
      timeFormat,
    };

    this.changeDebateState(debateRoundsPayload);

    this.activeDebate.current = {
      ...this.activeDebate.current,
      state: debateRoundsPayload,
    };

    try {
      if (
        (!this.currentUser?.isCoach && !this.getMemberWithHighUid()) ||
        (this.currentUser?.isCoach && !this.RoomMembers.length === 0)
      ) {
        await this.updateStateChange(debateRoundsPayload);
        await this.handleLastSetup();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleLeaveRoom() {
    try {
      this.hasLeftRoom.current = true;
      await this.removeParticipant();
      await this.closeTracks();

      this.navigate(-1);
    } catch (error) {
      console.log(error);
    }
  }

  AmIParticipants() {
    if (!this.activeDebate?.current || !this.currentUser) return;
    return this.activeDebate.current.teams.some((team) =>
      team.members.some((mem) => mem._id === this.currentUser?._id)
    );
  }

  async closeMic() {
    try {
      if (this.audioTracks) {
        this.audioTracks.localAudioTracks.setMuted(true);
        this.setMicMuted(true);
        // await this.addSpeechToChannel();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async openMic() {
    try {
      if (this.audioTracks) {
        this.audioTracks.localAudioTracks.setMuted(false);
        this.setMicMuted(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  createEndTimeFormat() {
    const { timeFormat } = this.activeDebate.current;
    let newTimeFormat = [...timeFormat];
    let endAt = newTimeFormat.reduce((time, format, i) => {
      let newTime;
      if (format.team === "break") {
        newTime = time + 10000;
        format.endTime = newTime;
        format.count = i + 1;
      } else {
        newTime = time + format.time * 60 * 1000;
        format.endTime = newTime;
        format.count = i + 1;
      }
      return newTime;
    }, Date.now());

    return { endAt, newTimeFormat };
  }

  getCurrentFormat(timeFormat) {
    if (!timeFormat) return;
    let resultFormat;
    for (let i = 0; i < timeFormat.length; i++) {
      let currentFormat = timeFormat[i];
      let now = Date.now();
      if (i === 0 && currentFormat.endTime > now) {
        resultFormat = currentFormat;
        break;
      } else {
        if (i !== 0) {
          let prev = timeFormat[i - 1].endTime;
          let curr = timeFormat[i].endTime;
          if (now > prev && now < curr) {
            resultFormat = currentFormat;
            break;
          }
        }
      }
    }

    return resultFormat;
  }

  handleTimer(debateState) {
    if (this.intervalRef.current?.length === 0 && debateState) {
      let { endAt: end } = debateState;
      let diffs = end - Date.now();
      if (diffs >= 0) {
        this.setSpeakTimeLeft(diffs);
      }

      let interval = setInterval(() => {
        const { endAt } = debateState;
        const diff = endAt - Date.now();
        if (diff >= 0) {
          this.setSpeakTimeLeft(diff);
        } else {
          this.intervalRef.current.forEach((int) => {
            clearInterval(int);
          });
          this.intervalRef.current = [];
          this.handleFinishRound();
        }
      }, 1000);

      this.intervalRef.current.push(interval);
    }
  }
}

export { DebateRoomServices };
