import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginUserApi, getOrCreateUserApi } from "../../../utils/Api";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../redux/store";
import { useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode"; // Correct the import for jwtDecode
import { setLoggedInUserDataToLS } from "../../../utils/services";
import useIsIOS from "../../../hook/useIsIOS";
import "../Auth.css";

const clientId = process.env.REACT_APP_CLIENT_ID;
const Login = () => {
  const isIOS = useIsIOS();

  const [loginData, setLoginData] = useState({
    error: "",
    data: {
      email: "",
      password: "",
    },
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { AddLoggedInUser, SetRefreshNow } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const toast = useToast();

  useEffect(() => {
    if (state?.from === "reset_password") {
      setLoginData((prev) => ({
        ...prev,
        data: { ...prev.data, email: state.email },
      }));
    }
  }, [state]);

  const handleInputChange = (name, value) => {
    setLoginData((prev) => ({
      ...prev,
      data: { ...prev.data, [name]: value },
    }));
  };

  const handleLogin = async () => {
    try {
      const res = await LoginUserApi(loginData.data);

      if (res.status === 200 && res.data.message) {
        const { message } = res.data;

        AddLoggedInUser(message);
        if (isIOS) {
          setLoggedInUserDataToLS(message);
        }
        if (state?.from === "reset_password") {
          navigate("/");
        } else {
          navigate(-1);
        }
        toast({
          title: "",
          description: "You logged in successfully",
          status: "success",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      } else {
        throw Error(res.data.message);
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message;
      // const isVerified = error?.response?.data?.verfied;

      toast({
        title: "",
        description: errorMsg ?? "Something went wrong",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
      console.log(error);
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      await handleLogin();
    }
  };

  const handleGoogleLogin = () => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/auth/google`, "_blank");
  };

  const handleCallbackResponse = async (authResult) => {
    const payload = jwtDecode(authResult.credential);
    let payloadData = {
      firstName: payload.given_name,
      lastName: payload.family_name,
      password: payload.given_name, // Consider revising how you handle passwords
      email: payload.email,
    };
    const { data, status } = await getOrCreateUserApi(payloadData);

    if (status === 201) {
      setLoggedInUserDataToLS({ user_id: data.message._id });
      SetRefreshNow(true);
      navigate("/");
    }
  };

  // for iOS Google signup
  useEffect(() => {
    if (isIOS && window.google) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCallbackResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        {
          theme: "outline",
          size: "large",
        }
      );

      window.google.accounts.id.prompt();
    }
  }, [isIOS]);

  return (
    <div className="AuthWrapper">
      <div className="login_main_box">
        <img
          draggable={false}
          className="logo_img"
          src="/images/logo.jpg"
          alt="logo"
        />
        <div className="login_welcome_text">
          {/* <h5 className='welcome_back_text'>Welcome back 😋</h5> */}
          {/* <p className='welcome_secondary_text'>Continue again where you left from </p> */}
        </div>
        {isIOS ? (
          <div id="signInDiv"></div>
        ) : (
          <div className="login_with_google_box" onClick={handleGoogleLogin}>
            <img src="/images/google.png" alt="googleIcon" />
            <p>Continue with Google</p>
          </div>
        )}
        <div className="sign_in_with_email_division">
          <div className="left_hr"></div>
          <p>or sign in with email</p>
          <div className="right_hr"></div>
        </div>
        <div className="form_wrapper">
          <div className="auth_input_item">
            <label>Email</label>
            <input
              onKeyDown={handleKeyDown}
              className="input_element"
              type="email"
              placeholder="Enter your email address"
              value={loginData.data.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
          </div>
          <div className="auth_input_item">
            <label>Password</label>
            <input
              onKeyDown={handleKeyDown}
              className="input_element"
              type="password"
              placeholder="Enter your password"
              value={loginData.data.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
            />
          </div>
          <button className="login_button" onClick={handleLogin}>
            Log in
          </button>
          <div className="bottom_other_options">
            <p
              onClick={() => navigate("/account/passwordresetlink")}
              className="forgot_password_text"
            >
              Forgot your password?
            </p>
            <Link to={"/signup"}>
              <p className="no_account_text">I don't have an account</p>
            </Link>
          </div>
          <div
            onClick={() => navigate("/account/verifyemail")}
            className="no_account_text verify_account_text"
          >
            Verify Account Now !!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
