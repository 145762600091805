"use client";

import { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  VStack,
  FormControl,
  FormLabel,
  Avatar,
  useDisclosure,
  Box,
  useToast,
} from "@chakra-ui/react";
import { BiEdit } from "react-icons/bi";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { updateUserapi } from "../../utils/Api";
import useUploadFile from "../../hook/useUploadFile";

export function UpdateProfile({
  children,
  firstName,
  lastName,
  email,
  profilePicture,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [editedUsername, setEditedUsername] = useState(firstName);
  const [editedLastName, setEditedLastName] = useState(lastName);
  const [editedProfilePic, setEditedProfilePic] = useState(profilePicture);
  const [profilePic, setProfilePic] = useState(null);
  const fileInputRef = useRef();
  const toast = useToast();
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
  const { SetRefreshNow } = bindActionCreators(actionCreators, dispatch);
  const { uploadFile } = useUploadFile();
  const { data } = useSelector((state) => state.user);

  const handleUpdate = async () => {
    try {
      console.log(`userid `, data);
      if (editing) return;
      if (!data?._id) return;
      const payload = {
        avatar: "",
        firstName: editedUsername,
        lastName: editedLastName,
      };
      setEditing(true);
      if (profilePic) {
        const image_url = await uploadFile(profilePic);
        payload.avatar = image_url;
      } else {
        delete payload.avatar;
      }

      await updateUserapi(data._id, payload);
      SetRefreshNow();
      toast({
        title: "",
        description: "Profile updated successfully",
        status: "success",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
      onClose();
      setProfilePic(null);
      setEditing(false);
    } catch (error) {
      setEditing(false);
    }
  };

  const handleCancel = () => {
    // Reset to original values
    setEditedUsername(firstName);
    setEditedProfilePic(profilePicture);
    setIsEditing(false);
    onClose();
  };

  const handleChangeFile = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setProfilePic(files[0]);
    }
  };

  return (
    <>
      <span className="cursor-pointer" onClick={onOpen}>
        {children}
      </span>

      <Modal isOpen={isOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Profile Details</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Box position="relative">
                <input
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleChangeFile}
                  type="file"
                  name="profilePic"
                />
                <Avatar
                  onClick={() => fileInputRef.current?.click()}
                  size="xl"
                  src={
                    profilePic
                      ? URL.createObjectURL(profilePic)
                      : editedProfilePic
                  }
                />
              </Box>

              <FormControl>
                <FormLabel>Firstname</FormLabel>
                <Input
                  value={editedUsername}
                  onChange={(e) => setEditedUsername(e.target.value)}
                  isDisabled={!isEditing}
                  variant="filled"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Lastname</FormLabel>
                <Input
                  value={editedLastName}
                  onChange={(e) => setEditedLastName(e.target.value)}
                  isDisabled={!isEditing}
                  variant="filled"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  className="border border-gray-400 p-2 rounded"
                  value={email}
                  isDisabled={true}
                  variant="filled"
                  type="email"
                />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCancel}>
              Cancel
            </Button>
            {!isEditing ? (
              <Button
                leftIcon={<BiEdit />}
                colorScheme="blue"
                onClick={() => setIsEditing(true)}
              >
                Edit Now
              </Button>
            ) : (
              <Button
                disabled={editing}
                leftIcon={<BiEdit />}
                colorScheme="blue"
                onClick={handleUpdate}
              >
                {editing ? "Updating..." : "Update"}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
